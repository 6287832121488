@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

/* Show/hide content for logged in users */
.single-course{
  &.single-course__user{
    .single-course__hero-user{
      display: block;
    }

    .single-course__hero-anonymous{
      display: none;
    }
  }

  &.single-course__anonymous{
    .single-course__hero-user{
      display: none;
    }

    .single-course__hero-anonymous{
      display: flex;
    }
  }
}


.page-template-single-course {

  .hero{

  }



  /* Modules */
  .modules {

    &__heading {

    }

    &-item {
      &__title--wrapper {

      }

      &__lessons {

      }
    }
  }

  @include breakpointMax(md){
    .info-buttons .wp-element-button {
      min-width: 210px;
      text-align: center;
      justify-content: center;
    }
  }
}



