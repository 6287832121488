@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.page-template-thank-you {

  .hero{
    padding-top: $primary-top-bottom-spacing !important;
    padding-bottom: $primary-top-bottom-spacing !important;
  }

  .related-products{

  }

  .donation{

  }

  .discount{

  }
}
