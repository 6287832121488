@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;

.empowered-with-logos__counters {
  
}

.empowered-with-logos__logos {
  column-gap: 26px;

  @include breakpointMax(md) {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 35px;
  } 
}