@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.page-template-practice-test {
  // Page Wrapper
  .page-wrapper {
    padding-bottom: 0 !important;
    padding-top: $primary-top-bottom-spacing !important;
  }

  // Page Left Column
  .hero {
    &__title {

    }

    &__text {

    }

    &__link {

    }
  }

  // Page Right Column
  .sidebar {

  }

  // Tabs
  .tabs-container {
    
  }
}