@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;
@use "../../abstracts/svg" as *;

.group-rate-request-form {

  .gform_wrapper{
    .gfield_error{
      align-self: flex-start!important;
    }

    .gfield_error .ginput_complex + .validation_message{
      display: block!important;
    }

    .gform_validation_errors{
      display: block;
    }
  }

  @include breakpoint(md) {
    .gfield_checkbox {
      display: flex;
      flex-wrap: wrap;
  
      .gchoice {
        flex: 0 1 50%;
        padding-right: 10px;
      }
    }

    div[data-conditional-logic] {
      grid-column: span 4 !important;
    }
  }
}