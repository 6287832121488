@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;
@use "../abstracts/svg" as *;

$button-social-login-size-mobile: 22px;
$button-social-login-size-desktop: 32px;

body.woocommerce-checkout {

  .woocommerce-order {
    padding: $primary-top-bottom-spacing 0;

    .thanks_get_started {
      margin-bottom: $primary-top-bottom-spacing;
    }
  }

  .logo {
    display: none !important;
  }

  h3 {
    color: var(--wp--preset--color--primary);
    font-size: var(--font-3);
    margin-top: 0;
    margin-bottom: 20px;
  }

  #customer_details {
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: 1fr;

    > p {
      display: none;
    }

    &:after, &:before {
      display: none;
    }
  }

  .shop_table {
    @include products-table;
    width: 100%;
    margin-bottom: 20px;

    thead {
      .product-name {
        padding-left: 40px;
      }

      .product-total {
        padding: 12px 8px !important;
      }
    }

    tbody {
      .product-name, .product-total {
        > * {
          height: 36px;
          padding-bottom: 0;
          margin-bottom: 0 !important;
          display: flex;
          align-items: center;

          &:last-child {
            padding-bottom: 0;
          }
        }
      }

      .product-name {
        .ts-product-name {
          font-weight: 600;
        }

        .bundled-product-name {
          padding-left: 32px;

          .ts-product-name {
            font-weight: 400;
            font-size: var(--font-body);
          }
        }

        .label_input {
          padding-left: 32px;
          position: relative;

          input[type='checkbox'] {
            @include form-radio;
            left: 32px;
            top: 10px;
            z-index: 2;
            cursor: pointer;
          }

          label {
            margin-bottom: 0 !important;

            &:before, &:after {
              cursor: pointer;
            }
          }

          .red {
            font-weight: 700;
          }
        }
      }

      .product-quantity {
        display: none;
      }

      .item-name {
        display: flex;
        align-items: center;

        > span:has(a) {
          position: relative;
          padding-right: 14px;
        }

        .ts-product-image {
          width: 32px;
          height: 32px;
          margin-right: 22px;
          object-fit: cover;
        }
      }

      .product-total {
        width: 17%;
        padding: 12px 8px !important;

        .woocommerce-Price-amount {
          font-weight: 600;
        }

        label {
          font-weight: 400 !important;
        }
      }

      .bundled_table_item {
        .product-total {
          .woocommerce-Price-amount {
            font-weight: 400;
            opacity: 0;
          }
        }
      }
    }
  }

  .sal-contribution-description {
    padding: 16px 10px;
    background-color: var(--wp--preset--color--grey-light-secondary);
    color: var(--wp--preset--color--grey-light-primary);
    opacity: 0.5;
    display: block;
    font-size: 12px;
    margin-top: 20px;

    > span {
      line-height: 1.4;
    }

    #donation_title {
      display: block;
      font-size: var(--font-body);
    }
  }

  .wc-stripe-banner-checkout {
    margin: auto;

    fieldset {
      width: 100%;
      text-align: center;
    }

    .banner-title {
      margin: 0 !important;
    }

    button {
      border-radius: 50px;
      height: 44px !important;
    }
  }

  .sal-contribution-input {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .form-row {
      margin-bottom: 0;
    }

    .update_totals_on_change {
      min-width: 130px;
      padding: 0;
    }

    label {
      font-weight: 400 !important;
      font-size: var(--font-body);
      margin: 0 16px 0 0 !important;
    }

    .select {
      @include form-select;
      @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none"> <g clip-path="url(#clip0_1711_9469)"> <path d="M6.5 4.6875L3.375 7.5L0.25 4.6875V3.75H6.5V4.6875Z" fill="black"/> </g> <defs> <clipPath id="clip0_1711_9469"> <rect width="6.25" height="10" fill="white" transform="translate(0.25)"/> </clipPath> </defs> </svg>');
      background-size: 6.25px;
    }
  }

  .col-2 {
    width: 100% !important;
    float: none !important;

    .woocommerce-error li {
      justify-content: center;
    }

    > p {
      //display: none;
    }

    p:empty {
      display: none;
    }
  }

  .col-1 {
    float: none !important;
    width: 100% !important;
  }

  .woocommerce-form-login-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    .login-text {
      margin-top: 0;
      margin-bottom: 0;
      padding: 0;
    }

    .wc-social-login {
      margin-left: auto;

      p:first-child {
        display: none;
      }

      p:nth-child(2) {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
        margin: 0;

        .button-social-login {
          font-size: 0;
          background: transparent;
          border: none;
          width: $button-social-login-size-mobile;
          height: $button-social-login-size-mobile;
          box-shadow: none;
          margin: 0;

          &.button-social-login-facebook {
            &:after {
              content: '';
              width: $button-social-login-size-mobile;
              height: $button-social-login-size-mobile;
              background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='32' viewBox='0 0 33 32' fill='none'%3E%3Cpath d='M32 16C32 7.4375 25.0625 0.5 16.5 0.5C7.9375 0.5 1 7.4375 1 16C1 23.7362 6.66813 30.1488 14.0781 31.3125V20.4806H10.1406V16H14.0781V12.585C14.0781 8.70062 16.3906 6.555 19.9325 6.555C21.6287 6.555 23.4025 6.8575 23.4025 6.8575V10.67H21.4475C19.5225 10.67 18.9219 11.865 18.9219 13.0906V16H23.2206L22.5331 20.4806H18.9219V31.3125C26.3319 30.1488 32 23.7362 32 16Z' fill='black'/%3E%3C/svg%3E") center no-repeat;
              background-size: contain;
              display: block;
            }
          }

          &.button-social-login-google {
            content: '';
            width: $button-social-login-size-mobile;
            height: $button-social-login-size-mobile;;
            background-size: contain;
            display: block;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='32' viewBox='0 0 31 32' fill='none'%3E%3Cg clip-path='url(%23clip0_128_3335)'%3E%3Cpath d='M31 16.3625C31 25.2062 24.9438 31.5 16 31.5C7.425 31.5 0.5 24.575 0.5 16C0.5 7.425 7.425 0.5 16 0.5C20.175 0.5 23.6875 2.03125 26.3937 4.55625L22.175 8.6125C16.6562 3.2875 6.39375 7.2875 6.39375 16C6.39375 21.4062 10.7125 25.7875 16 25.7875C22.1375 25.7875 24.4375 21.3875 24.8 19.1063H16V13.775H30.7563C30.9 14.5687 31 15.3312 31 16.3625Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_128_3335'%3E%3Crect width='30.5' height='32' fill='white' transform='translate(0.5)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E") center no-repeat;
            background-size: contain;
          }

          .si {
            display: none;
          }
        }
      }
    }
  }

  .woocommerce-billing-fields__field-wrapper {
    margin: 20px 0 4px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    border: 1px solid var(--wp--preset--color--grey-secondary);
    border-radius: 5px;

    br:first-of-type {
      display: none;
    }

    > p:first-child {
      display: none;
    }

    .form-row {
      margin-bottom: 14px;
      float: none;
      flex-basis: 100%;
      width: 100%;

      &.form-row-first, &.form-row-last {
        flex-basis: 50%;
      }

      &:last-of-type {
        margin-bottom: 0;
        padding-bottom: 0;
      }

      label {
        margin-bottom: 8px !important;
        line-height: 1;
        color: var(--wp--preset--color--dark-primary);
      }

      input {
        font-size: var(--font-body);
        position: relative;
      }

      .select2-selection {
        @include form-select;
        @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none"> <g clip-path="url(#clip0_1711_9469)"> <path d="M6.5 4.6875L3.375 7.5L0.25 4.6875V3.75H6.5V4.6875Z" fill="black"/> </g> <defs> <clipPath id="clip0_1711_9469"> <rect width="6.25" height="10" fill="white" transform="translate(0.25)"/> </clipPath> </defs> </svg>');
        background-size: 6.25px;

        &__arrow {
          display: none;
        }

        &__rendered {
          color: var(--wp--preset--color--grey-primary);
          font-size: var(--font-body);
          padding: 0;
        }
      }

      .checkbox {
        span {
          font-size: 12px;
        }

        span {
          font-weight: 400 !important;
        }

        #receive_text_messages {
          position: relative;
          opacity: 1;
          border: none;
          width: 10px;
          height: 10px;

          &::before {
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
            top: -1px;
            left: 0;
            background-color: var(--wp--preset--color--grey-extra-light);
            border: 1px solid var(--wp--preset--color--grey-primary);
            box-sizing: border-box;
          }

          &::after {
            content: ' ';
            width: 14px;
            height: 14px;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.44785 8L1 5.15593L1.61196 4.44491L3.44785 6.57796L7.38804 2L8 2.71102L3.44785 8Z' fill='white'/%3E%3C/svg%3E%0A");
            position: absolute;
            top: 0;
            left: 0;
            background-repeat: no-repeat;
            background-size: 10px;
            background-position: 3px 1px;
          }

          &:checked {
            &::before {
              background: var(--wp--preset--color--dark-primary);
              border-color: var(--wp--preset--color--dark-primary);
            }

            &::after {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .woocommerce-additional-fields {
    height: 0;
  }

  .select2-dropdown {
    color: var(--wp--preset--color--grey-primary);
    background-color: var(--wp--preset--color--grey-extra-light);
    border-color: var(--wp--preset--color--grey-medium);

    .select2-search__field {
      @include form-field;
    }
  }

  .checkout_coupon {
    display: flex;
    width: 100%;
    border: none !important;
    margin: 0 0 6px 0;
    padding: 0;
    align-items: center;
    flex-wrap: wrap;

    br {
      display: none;
    }

    input {
      font-size: var(--font-body);
    }

    p {
      margin-bottom: 0;
      padding: 0;

      &:first-child {
        flex-basis: 100%;
        font-weight: 700;
        margin-bottom: 8px;
        margin-top: 0;
        font-size: var(--font-body) !important;
        color: var(--wp--preset--color--dark-primary) !important;
      }

      &:nth-child(2) {
        margin-right: 12px;
        flex-grow: 1;
      }
    }

    .form-row-last {
      text-align: right;
      max-width: 180px;
    }

    .submit {
      align-self: center;
      max-width: 180px;
      flex-basis: 180px;
      margin-left: auto;
    }

    .button {
      max-height: 44px;
      min-height: 44px;
      width: 100%;
    }
  }

  #payment {
    background-color: transparent;
    border-radius: 5px 5px 0;
    border-top: 1px solid var(--wp--preset--color--grey-secondary);
    overflow: hidden;


    input.shipping_method {
      display: none !important;
    }

    .woocommerce-shipping-methods {
      li {
        text-align: left;
      }
    }

    .wc_payment_methods {
      padding: 8px 0;
      display: flex;
      flex-direction: column;
      border-color: var(--wp--preset--color--grey-secondary);
      border-style: solid;
      border-width: 0 1px 0;
      border-radius: 0;
      overflow: hidden;

      .wc_payment_method {
        padding: 12px 20px;
        background-color: var(--wp--preset--color--white);
        border-top: 1px solid var(--wp--preset--color--grey-medium);

        &:first-of-type {
          border-top: 0;
        }

        &:last-of-type {
          border-bottom: 1px solid var(--wp--preset--color--grey-medium);
        }

        &.payment_method_stripe_googlepay {
          label {
            &:before, &:after {
              top: 5px;
            }
          }
        }

        > label {
          &:before, &:after {
            @include breakpointMax(md){
              top: 5px!important;
            }
          }
        }

        > input {
          display: none !important;
        }

        &.payment_method_stripe_cc {
          background-color: transparent;
          border: none;

          > label {
            padding-left: 22px !important;
            background-color: var(--wp--preset--color--white);
            margin-bottom: 12px !important;
            display: block;
            width: 100%;

            &:before, &:after {
              top: 50%;
              transform: translateY(-50%);
              left: 0;
            }
          }

          .wc-stripe_cc-container {
            border: 1px solid var(--wp--preset--color--grey-medium);
            background-color: var(--wp--preset--color--white);
            margin-bottom: 0;

            #wc-stripe-card-element, .wc-stripe_cc-new-method-container {
              margin-bottom: 0;
            }

            .save-source-label {
              margin-bottom: 0 !important;
            }

            .wc-stripe-save-source {
              padding-left: 12px;
              padding-bottom: 12px;
            }

            .save-source-checkbox {
              border-radius: 0;
              border: none;
              height: auto;
              width: auto;
            }

            #stripe_cc_save_source_key:checked ~ .save-source-checkbox:after {
              border: none;
              width: 14px !important;
              height: 14px !important;
              transform: none;
              top: 0 !important;
              left: 0 !important;
            }
          }

          input[type=radio] {

            & + label.wc-stripe-label-payment-type {
              padding: 0 0 0 22px !important;
              color: var(--wp--preset--color--grey-light-primary) !important;
              margin: 10px 0 10px 8px !important;

              &:before {
                width: 13px;
                height: 13px;
              }
            }
          }

          .wc-stripe-saved-methods-container {
            padding: 8px;
          }

          .select2-selection__rendered, .select2-selection__arrow{
            margin-top: 0;
          }
        }

        &:last-of-type {
          margin-bottom: 0;
        }

        .payment_box {
          padding: 0;
          background-color: var(--wp--preset--color--white);
          margin: 0;

          &:before {
            display: none;
          }
        }
      }
    }

    .place-order {
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    button {
      margin-top: 20px;
      margin-bottom: 0 !important;
      min-width: 180px;
      width: auto !important;
      margin-left: auto;

      &.gpay-button {
        height: 44px;
        border-radius: 30px;
      }
    }
  }

  .gpay-button-container {
    text-align: right;

    button {
      padding: 12px !important;
      background-size: contain !important;
    }
  }

  .fee th {
    width: 100% !important;
  }

  .place-order {
    table {
      width: 100%;
      background-color: var(--wp--preset--color--white);
      border-collapse: collapse;
      display: flex;
      flex-flow: column nowrap;
      border-color: var(--wp--preset--color--grey-secondary);
      border-width: 0 1px 1px 1px;
      border-style: solid;
      border-radius: 0 0 5px 5px;
      color: var(--wp--preset--color--dark-primary);

      tbody, tr {
        width: 100%;
      }

      tr {
        display: flex;
      }

      th, td {
        padding: 12px 20px;
        text-align: right;
        border-bottom: 1px solid var(--wp--preset--color--grey-medium);
        display: flex;
        align-items: center;
      }

      th {
        width: 33%;
        text-align: left;
        justify-content: flex-start;
      }

      td {
        width: 100%;
        justify-content: flex-end;
      }

      .shipping {
        td {
          font-size: 14px;
        }

        input[type="radio"] {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;

          & + label,
          & + span {
            position: relative;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: 13px;
            padding-left: 17px;

            &::before {
              content: "";
              position: absolute;
              width: 13px;
              height: 13px;
              top: 3px;
              left: 0;
              background-color: transparent;
              border: 1px solid var(--wp--preset--color--grey-secondary);
              border-radius: 50%;
              box-sizing: border-box;
            }

            &::after {
              content: ' ';
              width: 9px;
              height: 9px;
              background-color: transparent;
              position: absolute;
              top: 5px;
              left: 2px;
              border-radius: 50%;
            }
          }

          &:checked {

            & + label,
            & + span {

              &::after {
                background-color: var(--wp--preset--color--dark-primary);
                background-image: none;
              }
            }
          }

          &:disabled {
            & + label,
            & + span {
              color: inherit !important;
            }
          }
        }
      }

      .cart-discount {
        th, td {
          background-color: var(--wp--preset--color--grey-light-secondary);
        }
      }

      .order-total {
        td, th {
          border: none;
        }
      }
    }
  }

  .credibility {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    column-gap: 20px;
    margin-top: 20px;

    .powered_by_stripe_icon {
      max-width: 150px;
    }

    .money_back_guarantee_icon {
      max-width: 85px;
      margin: 0 15px;
    }

    .rapid_ssl_icon {
      max-width: 75px !important;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  /* Login section */

  .login_shows {
    transition: $primary-animation;
    opacity: 1;
    height: auto;
    padding: 20px;
    border: 1px solid var(--wp--preset--color--grey-secondary);
    border-radius: 5px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    &.hidden {
      display: none;
    }

    .form-row {
      flex-basis: 100%;
      width: 100%;
      padding-bottom: 14px;
      margin-bottom: 0;

      &:has(.woocommerce-button) {
        flex-basis: auto;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;

        @include breakpointMax(xss){
          flex-direction: column-reverse;
          align-items: flex-start;
          .woocommerce-form-login__submit{
            margin-bottom: 20px;
          }
        }
      }

      label {
        margin-bottom: 8px !important;
        line-height: 1;
      }

      br {
        display: none;
      }

      input {
        font-size: var(--font-body);
      }

      .show-password-input {
        top: auto;
      }

      .woocommerce-form__label-for-checkbox {
        position: relative;
        line-height: 1;
        display: inline-flex;
        align-items: center;
        margin-left: 14px !important;
        margin-bottom: 0 !important;

        span {
          font-size: 14px !important;
          font-weight: 400 !important;
          color: var(--wp--preset--color--grey-light-primary);
        }
      }
    }

    .lost_password {
      margin: 0;
      flex-basis: auto;
      display: flex;
      align-items: center;
      padding-bottom: 14px;
    }

    .wc-social-login {
      flex-basis: 100%;
      width: 100%;
      margin-top: 14px;

      p {
        margin: 0;

        &:first-of-type {
          padding-bottom: 8px;
        }

        a {
          @include social-login;
          width: 100%;
          max-width: 210px;
        }
      }
    }

    .clear {
      display: none;
    }

    p:first-child {
      margin: 0;
    }

    * {
      float: none !important;
    }
  }

  /* End login section */

  @include breakpointMax(md) {
    .checkout_coupon {
      flex-wrap: wrap;

      p:first-child {
        max-width: 100%;
        flex-grow: 1;
        flex-basis: 100%;
        margin-bottom: 14px;
      }
    }

    .payment_method_stripe_cc {
      > label {
        &:before, &:after {
          top: 16px !important;
          transform: translateY(0) !important;
        }
      }

      span {
        margin-top: 16px;
        display: block;
        float: none;

        .wc-stripe-card-icon:first-child {
          margin-left: 0 !important;
        }

      }
    }

    .credibility {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .check_rapid {
        margin-top: 20px;
      }
    }

    .woocommerce-billing-fields__field-wrapper {
      .form-row {
        &.form-row-first, &.form-row-last {
          flex-basis: 100%;
        }
      }
    }
  }

  @include breakpoint(md) {
    .woocommerce-form-login-toggle {

      .wc-social-login {
        p:nth-child(2) {
          .button-social-login {
            width: $button-social-login-size-desktop;
            height: $button-social-login-size-desktop;

            &.button-social-login-facebook {
              &:after {
                width: $button-social-login-size-desktop;
                height: $button-social-login-size-desktop;
              }
            }

            &.button-social-login-google {
              width: $button-social-login-size-desktop;
              height: $button-social-login-size-desktop;
            }
          }
        }
      }
    }

    .login_shows {

      .form-row {

        &:has(.woocommerce-button) {
          width: auto;
        }
      }

    }
  }

  @include breakpoint(lg) {
    #customer_details {
      grid-template-columns: repeat(2, 1fr);

      .col-1,
      .col-2 {
        width: auto;
      }
    }
  }

  @include breakpointMax(xs) {
    .wp-element-button,
    .button {
      padding: 12px;
      width: 100%;
      font-size: var(--font-body) !important;
      min-width: auto;
    }
  }
}

.woocommerce-order-received {
  .wc-block-mini-cart {
    visibility: visible !important;
    width: auto !important;
  }
}

.form_hidden-checkout {
  display: none !important;
}

.woocommerce-checkout form.login .wc-social-login {
  display: block !important;
}

#billing_email[value]:not([value=""]) {
  pointer-events: none !important;
}
