@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.page-template-single-product {
  .certification-hero-primary {
    padding-top: $primary-top-bottom-spacing !important;
    padding-bottom: $primary-top-bottom-spacing !important;
    ul a {
      text-decoration: underline;
    }
  }

  .bundle-section {

  }

  .related-products {

  }

  .tabs-container {
    .wp-block-heading{
      text-align: center;
    }

  }

  .accordion-primary {

  }

  .certify-features{

  }
}

.certify-features{
  .swiper-wrapper{
    .swiper-slide{
      opacity: 0!important;
      &.swiper-slide-active{
        opacity: 1!important;
      }
    }
  }
}
