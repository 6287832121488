.certification-card {
  img {
    max-width: 245px;
  }

  .wp-block-buttons {
    max-width: 367px;
    margin-left: auto;
    margin-right: auto;
  }
}