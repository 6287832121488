@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;
@use "../abstracts/svg" as *;

.wc-block-components-notice-banner,
.woocommerce-message, .woocommerce-info {
  padding: 1em 2em;
  border: none;
  margin: 0 0 2em;
  font-size: var(--font-body);
  color: var(--wp--preset--color--grey-primary);
  align-items: center;
  display: flex;
  flex-direction: row;

  svg, &:before {
    display: none;
  }

  &:has(a) {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;

    a {
      margin-left: auto;
    }
  }

  &:has(a.restore-item) {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    a {
      justify-content: center;
    }
  }

  > .wc-block-components-notice-banner__content{
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 5px;

    br{
      display: none;
    }
  }

  .wp-element-button, a,
  > .wc-block-components-notice-banner__content .wc-forward{
    @include button-primary;
    font-weight: 500;
    padding: 12px 24px !important;
    opacity: 1;
    text-align: center;
    text-decoration: none;
    border-radius: 50px;
    min-width: 180px;
    color: var(--wp--preset--color--white)!important;
    background-color: var(--wp--preset--color--secondary-dark)!important;
  }

  @include breakpointMax(md) {

    > .wc-block-components-notice-banner__content{
      flex-direction: column;
    }

    &:has(a) {
      flex-direction: column-reverse;;
      justify-content: center;
      align-items: center;
      text-align: center;

      a {
        margin-top: 24px;
        margin-left: 0;
        margin-right: 0;
      }
    }

    &:has(a.restore-item) {
      flex-direction: column;
    }
  }
}

.woocommerce-error {
  padding: 1em 2em;

  &:before {
    display: none;
  }

  li {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    row-gap: 20px;
    text-align: center;

    &:not(:has(a)){
      flex-direction: row;
    }

    a {
      align-self: center;
    }
  }

  a {
    text-align: center;
  }

  @include breakpoint(md){
    li{
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
}

.cart {

  .woocommerce {
    .shop_table {
      @include products-table;
    }

    .cart-collaterals {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      h2 {
        color: var(--wp--preset--color--primary-dark);
        font-size: var(--font-2);
        margin-bottom: 20px;
        margin-top: 0;
      }

      .cart_totals, .cross-sells {
        float: none;
        width: auto;
      }

      .cross-sells {
        .products {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          column-gap: 10px;
          row-gap: 20px;

          &:before {
            display: none;
          }

          @include cross-sale-card;
        }

        @include breakpoint(md) {
          .products {
            gap: 20px;
            margin-bottom: 0;
          }
        }

      }

      .cart_totals {
        .shop_table {
          margin-bottom: 32px;

          tbody {
            tr {
              td, th {
                border-top: 1px solid var(--wp--preset--color--grey-secondary) !important;
                color: var(--wp--preset--color--dark-primary);
                display: table-cell !important;
                background-color: transparent;
              }

              td {
                text-align: right !important;
              }

              &:nth-of-type(2),
              &:nth-of-type(3) {
                background-color: var(--wp--preset--color--grey-light);
              }

              &:nth-of-type(4) {
                background-color: var(--wp--preset--color--white);
              }

              &:first-of-type {
                td, th {
                  border-top: none !important;
                }
              }
            }
          }

          .woocommerce-shipping-methods {
            font-size: var(--font-7);
          }

          .woocommerce-shipping-destination {
            font-size: var(--font-7);

            strong {
              color: var(--wp--preset--color--dark-primary);
            }
          }

          input[type="radio"] {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;

            & + label,
            & + span {
              position: relative;
              font-size: inherit !important;
              font-weight: inherit !important;
              line-height: 13px;
              padding-left: 17px;

              &::before {
                content: "";
                position: absolute;
                width: 13px;
                height: 13px;
                top: 3px;
                left: 0;
                background-color: transparent;
                border: 1px solid var(--wp--preset--color--grey-secondary);
                border-radius: 50%;
                box-sizing: border-box;
              }

              &::after {
                content: ' ';
                width: 9px;
                height: 9px;
                background-color: transparent;
                position: absolute;
                top: 5px;
                left: 2px;
                border-radius: 50%;
              }
            }

            &:checked {

              & + label,
              & + span {

                &::after {
                  background-color: var(--wp--preset--color--dark-primary);
                }
              }
            }

            &:disabled {
              & + label,
              & + span {
                color: inherit !important;
              }
            }
          }

          .order-total {
            .woocommerce-Price-amount {
              color: var(--wp--preset--color--primary-dark);
            }
          }
        }

        .wc-proceed-to-checkout {
          padding: 0;
          margin: 0 0 16px;
          display: grid;
          justify-content: center;

          .checkout-button {
            @include button-primary;
            max-width: 243px;
            height: 44px;
            font-size: var(--font-body);
            padding: 12px 24px;
            margin-left: auto;
            margin-bottom: 0;
            z-index: 3;
          }

          .wc_stripe_cart_payment_methods {
            margin-left: auto;
            margin-bottom: 0;

            .wc-stripe-payment-method.or {
              color: var(--wp--preset--color--dark-primary);
              font-size: var(--font-body);
              line-height: 1.4;

              p {
                padding-bottom: 0;
              }
            }
          }
        }
      }

      .gpay-button, .apple-pay-button{
        border-radius: 50px;
        height: 42px!important;
      }
    }

    @include breakpointMax(xs){
      .wp-element-button{
        padding: 12px;
        width: 100%;
        font-size: var(--font-body)!important;
      }
    }

    @include breakpoint(md) {
      .cart-collaterals {
        flex-direction: row;

        .cart_totals, .cross-sells {
          flex: 1 0 50%;
        }

        .cart_totals {
          padding-left: 47px;

          .wc-proceed-to-checkout {
            justify-content: right;
          }
        }
      }
    }
  }

  .wc-block-components-notice-banner {
    > .wc-block-components-notice-banner__content{
      &:has(a) {
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;

        a {
          margin-left: auto;
        }
      }

      &:has(a.restore-item) {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        a {
          justify-content: center;
        }
      }
    }
  }

  .credibility {
    text-align: center;
    padding:0;
    margin: 0 auto;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    justify-content: center;

    svg{
      width: 100%;
      height: auto;
    }

    .powered_by_stripe_icon{
      max-width: 150px;
      @include breakpointMax(md) {
        max-width: 120px;
      }
    }

    .money_back_guarantee_icon{
      max-width: 85px;
      margin: 0px 15px;
    }

    .rapid_ssl_icon{
      max-width: 75px !important;
      @include breakpointMax(md) {
        max-width: 60px;
      }
    }

    @include breakpoint(md){
      justify-content: flex-end;
    }
  }

  .wc-stripe-card-icons-container {
    margin-bottom: 16px;

    @include breakpoint(lg) {
      margin-left: -230px;
      position: relative;
      margin-bottom: -85px;
      margin-top: 5px;
    }

    img {
      max-width: 50px;
      @include breakpointMax(lg) {
        max-width: 46px;
      }
    }
  }
}

.woocommerce-page .woocommerce-cart-form .product-thumbnail {
  width: 56px !important;
}

/* Delete Confirmation Pop-Up */

#confirmation-modal {
  display: none;
  position: fixed;
  z-index: 5001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.35);

  .confirmation-popup {
    border-radius: 5px;
    background-color: var(--wp--preset--color--primary);
    max-width: 320px;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20%;
    box-shadow: 0 0 7px 2px rgba(0, 0, 0, 0.3);
    padding: 7px;
    z-index: 5002;

    .popup-title {
      color: var(--wp--preset--color--white);
      text-align: center;
      position: relative;
      margin-bottom: 16px;

      &:before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 512 512' fill='%23fff'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--%3E%3Cpath d='M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480H40c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24V296c0 13.3 10.7 24 24 24s24-10.7 24-24V184c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        text-align: center;
        width: 70px;
        height: 70px;
        margin: 0 auto 20px;
      }

      i {
        display: none;
      }
    }

    .button-group {
      background-color: var(--wp--preset--color--white);
      padding: 25px;
      position: relative;

      a {
        display: block;
        max-width: 200px;
        margin: 0 auto;
        text-align: center;
        text-decoration: none;
        border-width: 2px;
        border-style: solid;
        font-family: inherit;
        font-size: inherit;
        font-weight: 400;
        line-height: 1;
        padding: 12px 24px;
        border-radius: 50px;

        &.exit-btn {
          @include button-primary;
          margin-bottom: 16px;

        }

        &.confirm-delete {
          color: var(--wp--preset--color--grey-light-primary);
          border-color: var(--wp--preset--color--grey-light-primary);

          &:hover {
            background-color: var(--wp--preset--color--grey-light-primary);
            color: var(--wp--preset--color--white);
          }
        }
      }
    }

    .exit-popup.close {
      color: var(--wp--preset--color--white);
      text-shadow: none;
      opacity: 1;
      cursor: pointer;
    }
  }
}

.custom-undo-alert {
  text-align: center;
  margin-bottom: 36px;

  h3{
    color: var(--wp--preset--color--primary);
    font-size: var(--font-3);
    margin-top: 0;
    margin-bottom: 16px;
  }

  i {
    top: 0;
    font-size: 50px;
    display: block;
    margin-bottom: 16px;
    margin-top: 0;
    color: var(--wp--preset--color--primary);
  }

  .undo-btn {
    @include button-primary;
    display: block;
    max-width: 200px;
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
    border-width: 2px;
    border-style: solid;
    font-family: inherit;
    font-size: inherit;
    font-weight: 400;
    line-height: 1;
    padding: 12px 24px;
    border-radius: 50px;
  }
}

.wc-block-store-notices[data-block-name="woocommerce/store-notices"]:has(.custom-undo-alert){
  display: none !important;
}

.animate {
  animation: animatezoom 0.6s;
}

@keyframes animatezoom {
  from {
    transform: scale(0), translateX(-50%);
  }
  to {
    transform: scale(1), translateX(-50%);
  }
}
