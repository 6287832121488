@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;
@use "../../abstracts/svg" as *;

.primary-popup {
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  box-sizing: border-box;

  &.toggled {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .levit-popup-wrapper {
    padding: 0;
    width: 100%;
    max-width: 720px;
    min-width: 0;
    border-radius: 0;
    max-height: 100vh;
    transform: none !important;
  }

  .wp-block-custom-close-popup {
    position: absolute;
    top: 5px;
    right: 20px;
    text-align: right;

    a {
      text-decoration: none;
      font-size: 30px;
      color: var(--wp--preset--color--white);
      transition: $primary-animation;

      &:hover {
        color: var(--wp--preset--color--secondary-dark);
      }
    }
  }

  @include breakpointMax(md) {
    .wp-block-custom-close-popup {
      top: 5px;
      right: 5px;
      
      a {
        font-size: 20px;
      }
    }
  }
}

.primary-popup-button {
    @include button-secondary;
}
