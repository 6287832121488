@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;
@use "../../abstracts/svg" as *;

.megacode-form {
  .gquiz-answer-explanation {
    &-failed,
    &-passed {
      margin-top: 20px !important;
      padding: 60px 20px !important;
      align-items: center;

      > br {
        display: none;
      }

      > h4 {
        margin-bottom: 30px !important;
      }

      > h3 {
        margin: 0 !important;
      }

      > a {
        display: inline-flex;
        margin-top: 30px !important;
      }
    }

    > a {
      margin-top: 30px;
    }

    &__cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;

      & > br {
        display: none;
      }
    }

    &__card {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      background: var(--wp--preset--color--white);
      border: 1px solid var(--wp--preset--color--grey-secondary);
      border-radius: 5px;

      & > br {
        display: none;
      }

      * {
        text-align: center;
      }

      p {
        margin-bottom: 8px;
      }

      a {
        margin-bottom: 8px;
        line-height: 1.4;
      }
    }
  }

  .button-with-arrow {
    margin-top: auto;
    padding-top: 16px;
  }

  .button-with-arrow a {
    color: var(--wp--preset--color--white) !important;
  }

  input[type="submit"],
  .gform_previous_button,
  .gform_next_button {
    min-width: 130px !important;
  }

  @include breakpointMax(md) {
    .gquiz-answer-explanation {
      &-failed,
      &-passed {
        padding: 20px !important;
      }

      &__cards {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}