@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;

.empowered-counters {
  @include breakpointMax(md) {
      display: flex !important;
      flex-wrap: wrap !important;
      column-gap: 0;

    .wp-block-column {
      flex: 0 1 100% !important;

      &:first-child {
        flex: 0 1 50% !important;
        order: 1;
      }

      &:last-child {
        flex: 0 1 50% !important;
        order: 2;
      }
    }

    &__number {
      font-size: 28px !important;
    }

    &__description {
      max-width: 143px;
    }
  }
}