#mc_embed_signup_scroll {
  display: flex;
  flex-direction: column;

  h3 {
    order: 1;
  }

  p {
    &:nth-child(2) {
      order: 2;
      margin-bottom: 14px;
    }
  }

  label{
    display: block;
  }

  .mc-field-group {
    margin-bottom: 16px!important;
    &:nth-of-type(2) {
      order: 3;
    }

    &:nth-of-type(3) {
      order: 4;
    }
  }

  #mce-responses {
    order: 6;
  }

  div {
    &:nth-child(3) {
      order: 5;
      text-align: left !important;
    }

    &:nth-child(7) {
      order: 7;
    }

    &:last-child {
      order: 8;
    }
  }
}