@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.certification-hero-primary {
  @include breakpointMax(lg) {
    .wp-block-columns {
      display: flex;
      flex-direction: column;
    }

    .wp-block-column {
      flex: 0 1 100%;

      &:first-child {
        order: 1;
      }

      &:last-child {
        order: -1;
      }
    }
  }
  @include breakpointMax(xss) {
    .wp-block-outermost-icon-block {
      .icon-container {
        width: 100% !important;
      }
    }
  }
}


