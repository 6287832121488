@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;
@use "../../abstracts/svg" as *;

$success-green: #c7fb9d;

.gform_wrapper {
  margin: 0 !important;
  padding: 0 !important;

  .gform_fields {
    align-items: flex-end !important;
    padding: 0;
    margin: 0;
    list-style: none;
    grid-row-gap: 18px !important;
  }

  .gfield {
    margin: 0 !important;
    padding: 0 !important;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .ginput_complex {
    row-gap: 18px;

    .gform-grid-col {
      margin-bottom: 0 !important;
    }

    .gform-field-label--type-sub {
      padding: 0 !important;
      margin: 4px 0 0 0 !important;
      font-size: 12px !important;
      font-weight: 400 !important;
    }
  }

  .gfield_label:has(+ .gfield_description) {
    margin-bottom: 8px !important;
  }

  .gfield_description {
    font-size: 12px !important;
    line-height: 1.6em !important;
    margin: 0 0 12px 0 !important;
    padding: 0 !important;
  }

  .gf_progressbar_wrapper {
    .gf_progressbar_title {
      font-size: 20px !important;
      font-weight: 400 !important;
      color: var(--wp--preset--color--primary-light) !important;
      padding: 0 !important;
      margin: 0 0 4px 0 !important;
    }
  }

  .gchoice {
    position: relative;
  }

  .gquiz-answer-explanation {
    background: var(--wp--preset--color--grey-light);
    margin: 0 !important;
    padding: 20px !important;
    border: 1px solid var(--wp--preset--color--grey-medium);

    h4 {
      margin: 0 0 8px 0 !important;
      padding: 0 !important;
      font-size: var(--font-4) !important;
      font-weight: 400 !important;
      color: var(--wp--preset--color--primary) !important;
    }

    img {
      margin-top: 16px;
    }
  }

  .gquiz-correct-choice {
    font-weight: 700 !important;
    background: $success-green;
  }

  .ginput_container_date {
    max-width: calc(100% / 3)!important;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .gform_footer {
    margin-top: 30px;
  }

  input[type="submit"],
  .gform_previous_button,
  .gform_next_button  {
    min-width: 130px;
  }

  .gform_ajax_spinner {
    width: 0 !important;
    display: none !important;
  }

  .gform_required_legend,
  .gform_validation_errors {
    display: none;
  }

  .gfield_error {
    label, legend {
      color: var(--wp--preset--color--secondary) !important;
    }

    input[type="radio"] + label,
    input[type="checkbox"] + label {
      color: inherit !important;
    }

    [aria-invalid="true"] {
      border-color: var(--wp--preset--color--secondary) !important;
    }

    .validation_message {
      margin: 8px 0 0 0 !important;
      padding: 0 !important;
      color: var(--wp--preset--color--secondary) !important;
      background: none !important;
      border: 0 !important;
    }

    .ginput_complex + .validation_message {
      display: none !important;
    }
  }

  .gfield_required_asterisk {
    vertical-align: top;
    line-height: 1em;
  }

  .quiz-test-sec .practice .gform_body input {
    background-color: var(--wp--preset--color--grey-extra-light) !important;
    border: 1px solid var(--wp--preset--color--grey-medium) !important;
  }
}

.gform_confirmation_wrapper {
  margin: 0 !important;
  padding: 0 !important;

  .gform_confirmation_message {
    font-size: 20px;
    font-weight: 700;
    color: var(--wp--preset--color--primary-light);
  }
}

.gform_confirmation_message--quiz {
  h4 {
    margin: 0 0 8px 0 !important;
    padding: 0 !important;
    font-size: var(--font-4) !important;
    font-weight: 400 !important;
    color: var(--wp--preset--color--primary) !important;
  }

  h3 {
    margin: 0 0 20px 0 !important;
    padding: 0 !important;
    font-size: var(--font-3) !important;
    font-weight: 400 !important;
    color: var(--wp--preset--color--primary) !important;
  }

  .gquiz-answer-explanation {
    background: var(--wp--preset--color--grey-light);
    margin: 0 !important;
    padding: 20px !important;
    line-height: 1.4em !important;
    border: 1px solid var(--wp--preset--color--grey-medium);
    display: flex;
    flex-direction: column;
  }

  .gquiz-field {

    ul {
      display: inline-block;
      list-style: none;

      li {
        position: relative;

        &::before {
          content: '•';
          margin-right: 8px;
        }
      }
    }
  }

  .gquiz-correct-choice {
    background: $success-green;
  }

  #gquiz_confirmation_message {
    .gquiz-answer-explanation {
      h3, h4 {
        font-weight: 400 !important;

        * {
          font-weight: 400 !important;
        }
      }
    }
  }
}

.gftetimertext {
  position: static !important;

  .gfteCountdownTimer {
    position: static !important;
    font-size: 26px;
    color: var(--wp--preset--color--secondary);
    transform: none !important;
  }

  @include breakpointMax(md) {
    .gfteCountdownTimer {
      font-size: 20px;
    } 
  }
}