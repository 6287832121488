@use "./variables" as *;
@use "../abstracts/svg" as *;
@use "sass:map";

/* Media Queries */
@mixin breakpoint($key) {
  $size: map.get($breakpoints, $key);

  @media only screen and (min-width: $size) {
    @content;
  }
}

@mixin breakpointMax($key) {
  $size: map.get($breakpoints, $key);

  @media only screen and (max-width: ($size - 1)) {
    @content;
  }
}

/* Center block */
@mixin center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* Column width with margin */
@mixin column-width($numberColumns: 3) {
  width: map-get($columns, $numberColumns) - ( ( $columns__margin * ( $numberColumns - 1 ) ) / $numberColumns );
}

/* Form Field */
@mixin form-field {
  color: var(--wp--preset--color--grey-primary);
  padding: 16px !important;
  background-color: var(--wp--preset--color--grey-extra-light);
  border: 1px solid var(--wp--preset--color--grey-medium);
  box-shadow: none;
  outline: none;
  border-radius: 0;

  &:focus {
    border-color: var(--wp--preset--color--grey-light-primary);
  }

  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }
}

@mixin form-field-tel {
  color: var(--wp--preset--color--grey-primary);
  padding: 16px;
  background-color: var(--wp--preset--color--grey-extra-light);
  border: 1px solid var(--wp--preset--color--grey-medium);
  box-shadow: none;
  outline: none;
  border-radius: 0;

  &:focus {
    border-color: var(--wp--preset--color--grey-light-primary);
  }

  &::placeholder {
    color: inherit;
    opacity: 0.5;
  }
}

/* Form Select */
@mixin form-select {

  @include form-field;
  @include background-svg('<svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none"><path d="M1 1L7 7L13 1" stroke="#1A1E47" stroke-linecap="round" stroke-linejoin="round"/></svg>');

  background-repeat: no-repeat;
  background-size: 12px;
  background-position: calc(100% - 20px) 50%;
  appearance: none;
  padding-right: 40px;

  option {
    color: inherit;
  }
}

/* Form Checkbox */
@mixin form-checkbox {
  position: absolute;
  opacity: 0;

  & + label,
  & + span {
    position: relative;
    font-size: 12px !important;
    font-weight: 400 !important;
    padding-left: 24px;

    &::before {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      top: -1px;
      left: 0;
      background-color: var(--wp--preset--color--grey-extra-light);
      border: 1px solid var(--wp--preset--color--grey-primary);
      box-sizing: border-box;
      box-shadow: none;
      transform: translateY(0);
    }

    &::after {
      content: ' ';
      width: 14px;
      height: 14px;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.44785 8L1 5.15593L1.61196 4.44491L3.44785 6.57796L7.38804 2L8 2.71102L3.44785 8Z' fill='white'/%3E%3C/svg%3E%0A");
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: 3px 1px;
    }
  }

  &:checked {

    & + label,
    & + span {
      font-weight: 700 !important;

      &::before {
        background: var(--wp--preset--color--dark-primary);
        border-color: var(--wp--preset--color--dark-primary);
      }

      &::after {
        opacity: 1;
      }
    }
  }
}

/* Form Radio */
@mixin form-radio {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  & + label,
  & + span {
    position: relative;
    font-size: inherit !important;
    font-weight: inherit !important;
    line-height: 1.4;
    padding-left: 22px;

    &::before {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      top: 4px;
      left: 0;
      background-color: var(--wp--preset--color--grey-extra-light);
      border: 1px solid var(--wp--preset--color--grey-primary);
      border-radius: 50%;
      box-sizing: border-box;
      box-shadow: none;
      transform: translateY(0);
    }

    &::after {
      content: ' ';
      width: 14px;
      height: 14px;
      background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.44785 8L1 5.15593L1.61196 4.44491L3.44785 6.57796L7.38804 2L8 2.71102L3.44785 8Z' fill='white'/%3E%3C/svg%3E%0A");
      position: absolute;
      top: 4px;
      left: 0;
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: center;
    }
  }

  &:checked {

    & + label,
    & + span {

      &::before {
        background-color: var(--wp--preset--color--dark-primary)!important;
      }

      &::after {
        opacity: 1;
      }
    }
  }

  &:disabled {
    & + label,
    & + span {
      color: inherit !important;
    }
  }
}

/* Form File */
@mixin form-file {
  font-size: 12px;

  &::file-selector-button {

    @include form-field;
    padding-left: 68px;
    padding-right: 28px;
    margin-right: 10px;
    font-size: 14px;
    background-repeat: no-repeat;
    background-size: 24px;
    background-position: 28px 50%;
    border-radius: 0;
  }
}

/* Form Buttons */
@mixin form-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  min-width: 100px;
  height: 44px;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  color: var(--wp--preset--color--white);
  background: var(--wp--preset--color--secondary-dark);
  border: 1px solid var(--wp--preset--color--secondary-dark);
  border-radius: 50px;
  box-shadow: none;
  transition: $primary-animation;
  opacity: 1;
  outline: none;
  cursor: pointer;

  &:hover {
    background: var(--wp--preset--color--secondary);
    border: 1px solid var(--wp--preset--color--secondary);
  }
}

/* Buttons */

@mixin button-primary {
  color: var(--wp--preset--color--white);
  background-color: var(--wp--preset--color--secondary-dark);
  border-color: var(--wp--preset--color--secondary-dark);
  line-height: 1em;

  &:hover {
    color: var(--wp--preset--color--white);
    background-color: var(--wp--preset--color--secondary);
    border-color: var(--wp--preset--color--secondary);
  }
}

@mixin button-secondary {
  color: var(--wp--preset--color--white);
  background-color: var(--wp--preset--color--primary-dark);
  border-color: var(--wp--preset--color--primary-dark);

  &:hover {
    color: var(--wp--preset--color--white);
    background-color: var(--wp--preset--color--primary-light);
    border-color: var(--wp--preset--color--primary-light);
  }
}

@mixin button-with-arrow{
  display: block;

  &:after {
    content: '';
    width: 7px;
    height: 12px;
    margin-left: 10px;
    background: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.17705 5.89485C7.51176 6.22955 7.51176 6.77312 7.17705 7.10783L2.03592 12.249C1.70121 12.5837 1.15764 12.5837 0.822931 12.249C0.488222 11.9143 0.488222 11.3707 0.822931 11.036L5.35891 6.5L0.825609 1.96402C0.4909 1.62931 0.4909 1.08574 0.825609 0.751032C1.16032 0.416323 1.70389 0.416323 2.0386 0.751032L7.17973 5.89217L7.17705 5.89485Z' fill='white' stroke='white'/%3E%3C/svg%3E%0A") no-repeat center;
    display: inline-block;
    background-size: contain;
  }
}

@mixin button-with-arrow-left{
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &:before{
    content: '';
    width: 7px;
    height: 12px;
    margin-right: 10px;
    background: url("data:image/svg+xml,%3Csvg width='8' height='13' viewBox='0 0 8 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.17705 5.89485C7.51176 6.22955 7.51176 6.77312 7.17705 7.10783L2.03592 12.249C1.70121 12.5837 1.15764 12.5837 0.822931 12.249C0.488222 11.9143 0.488222 11.3707 0.822931 11.036L5.35891 6.5L0.825609 1.96402C0.4909 1.62931 0.4909 1.08574 0.825609 0.751032C1.16032 0.416323 1.70389 0.416323 2.0386 0.751032L7.17973 5.89217L7.17705 5.89485Z' fill='white' stroke='white'/%3E%3C/svg%3E%0A") no-repeat center;
    display: inline-block;
    background-size: contain;
    transform: scale(-1);
  }
}

@mixin social-login{
  position: relative;
  display: inline-block;
  vertical-align: middle;
  line-height: 2.5em;
  height: 2.5em;
  font-size: 100%;
  font-family: inherit;
  font-weight: 700;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid;
  color: #fff;
  padding: 0 0.7em 0 0;
  margin: 6px 0;
  border-radius: 2px;
  box-sizing: content-box;
  cursor: pointer;
  box-shadow: 0 1px 2px rgba(0,0,0,.1);
  text-decoration: none;
  transition: $primary-animation;

  .si, .fab{
    display: inline-block;
    vertical-align: top;
    text-align: center;
    font-size: 100%;
    width: 2.5em;
    height: 2.5em;
    padding: 0;
    margin-right: 0.7em;
    background-color: var(--wp--preset--color--white);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 1.8em 1.8em;
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;

    &:before{
      display: none;
    }

    &.si-facebook, &.fa-facebook {
      background-image: url("../images/facebook.svg");
    }

    &.si-google,&.fa-google {
      background-image: url("../images/google.svg");
    }
  }

  &.button-social-login-facebook {
    background: #3b5998;
    border-color: #3b5998;
  }

  &.button-social-login-google {
    background: #4285f4;
    border-color: #4285f4;
  }
}

/* Lists */

@mixin lessons-list-item{
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 0;

    a {
      text-decoration: none;
      padding: 12px 20px;
      border-bottom: 2px solid var(--wp--preset--color--grey-secondary);
      background-color: var(--wp--preset--color--grey-light);
      display: flex;
      align-items: center;
      gap: 20px;
      transition: all 0.3s ease-in-out;

      &:before {
        content: '';
        display: inline-block;
        border-radius: 50%;
        border: 2px solid rgba(88, 88, 95, 0.18);
        background: var(--wp--preset--color--white);
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }

      &:hover, &.active-menu {
        background-color: var(--wp--preset--color--grey-extra-light);
        &:before {
          background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cstyle%3Esvg%7Bfill:%2358585f2e%7D%3C/style%3E%3Cpath d='M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z'/%3E%3C/svg%3E") center no-repeat;
          background-size: cover;
        }
      }
    }
  }
}

/* Woocommerce*/
 @mixin cross-sale-card{
   .product {
     width: 100%;
     margin: 0;
     float: none;
     display: flex;
     flex-direction: column;
     border-radius: 5px;
     border: 1px solid var(--wp--preset--color--grey-secondary);
     padding: 20px 14px 16px;
     text-align: center;

     .attachment-woocommerce_thumbnail {
       width: 100%;
       height: auto;
       margin-bottom: 20px;
       object-fit: cover;
     }

     .woocommerce-loop-product__title {
       padding: 0;
       margin-bottom: 4px;
       font-weight: 400;
       color: var(--wp--preset--color--primary-light);
       font-size: var(--font-3)!important;
     }

     .woocommerce-loop-product__link{
       display: flex;
       flex-direction: column;
       flex-grow: 1;
     }

     .price {
       margin-bottom: 20px;
       margin-top: auto;
       font-weight: 400;
       color: var(--wp--preset--color--secondary);
       font-size: var(--font-body);
     }

     .button {
       @include button-with-arrow;
       align-self: center;
       min-width: 0;
       margin-top: auto;
       font-size: 14px;
       line-height: 1;
       display: inline-block;
     }
   }

   .onsale {
     display: flex;
     align-items: center;
     width: 47px;
     height: 47px;
     background-color: var(--wp--preset--color--secondary);
     padding: 10px;
     font-size: 12px;
     top: -10px!important;
     left: -10px!important;
   }

   @include breakpoint(md) {
     .product {
       padding: 24px 18px 16px;

       .woocommerce-loop-product__title {
         font-size: 20px;
       }

       .attachment-woocommerce_thumbnail {
         max-height: 186px;
       }

       .button {
         min-width: 180px;
         font-size: var(--font-body);
       }
     }
   }
 }

@mixin products-table {
  border-radius: 4px;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid var(--wp--preset--color--grey-secondary);
  overflow: hidden;

  thead {
    background-color: var(--wp--preset--color--grey-extra-light);
    color: var(--wp--preset--color--dark-primary);
    display: table-header-group !important;

    tr {
      display: table-row !important;
    }

    th {
      padding: 12px!important;
      line-height: 1.25;
      display: table-cell !important;
    }
  }

  tbody {
    td {
      padding: 12px;
      display: table-cell !important;
      text-align: left !important;
      border-top: 1px solid var(--wp--preset--color--grey-secondary) !important;

      &:before {
        display: none;
      }
    }

    tr {
      display: table-row !important;

      &:nth-of-type(even) {
        background-color: var(--wp--preset--color--grey-light);
      }
    }
  }

  .product-remove {
    padding-right: 8px;
    position: relative;
    width: 1rem !important;
  }

  .remove {
    font-size: 0;
    width: 14px;

    &:after {
      content: "";
      width: 14px;
      height: 14px;
      display: block;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14' fill='none'%3E%3Cpath d='M10.5 3.5L3.5 10.5' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.5 3.5L10.5 10.5' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") no-repeat center;
      background-size: contain;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .product-thumbnail {
    &:before {
      display: none;
    }

    a {
      display: flex;;
      align-items: center;

      img {
        width: 32px;
      }
    }
  }

  .product-name {
    a {
      color: var(--wp--preset--color--grey-primary);
      text-decoration: none;
    }

    .bundled-product-name {
      padding-left: 18px;

      a {
        font-size: var(--font-body) !important;
      }
    }
  }

  .product-quantity {
    text-align: center !important;

    label {
      font-weight: 400 !important;
    }
  }

  .coupon {
    display: flex;
    align-content: center;

    #coupon_code {
      @include form-field;
      width: 173px!important;
      height: 46px;
      background-color: var(--wp--preset--color--white);
      margin-right: 4px;
      font-size: var(--font-body);
    }

    .wp-element-button {
      @include button-secondary;
      max-width: 180px;
      height: 44px;
      line-height: 1;
    }

    ~ .wp-element-button {
      display: none;
    }
  }

  @include breakpointMax(xs){
    font-size: 14px;

    .coupon{
      #coupon_code{
        width: auto;
      }
      .wp-element-button{
        min-width: auto!important;
      }
    }
  }

  @include breakpointMax(md) {
    thead, tbody {
      td, th {
        padding: 12px 8px;
      }

      th.product-thumbnail,
      td.product-thumbnail,
      th.product-price,
      td.product-price {
        display: none !important;
      }
    }
  }

  @include breakpoint(lg) {
    .coupon {
      .wp-element-button {
        width: 243px;
        height: 44px;
      }
    }
  }
}

/* Tables */

@mixin table-primary{
  table {
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
  }

  td {
    padding: 12px;
  }

  td, th{
    border: 2px solid var(--wp--preset--color--grey-medium);
  }

  thead {
    border: 0;

    td, th{
      background-color: var(--wp--preset--color--blue-light-primary);
      color: var(--wp--preset--color--white);
      text-transform: uppercase;
      font-size: var(--font-body);
    }

    th {
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.4;
    }
  }

  tbody {
    tr:nth-child(even) {
      background: var(--wp--preset--color--grey-light-secondary);
    }
  }

  @include breakpointMax(lg) {
    table * {
      font-size: 14px !important;
    }
  }
}
