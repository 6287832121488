@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;

.all-products-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 40px;
  margin-bottom: 100px;

  &:last-child {
    margin-bottom: 0;
  }

  @include breakpointMax(md) {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 0;

    &:last-child {
      margin-top: 40px !important;
    }
  }
}