.megacode-card {
  &__title {

  }

  &__subtitle {
    color: var(--wp--preset--color--primary);
  }

  &__text {

  }

  &__button {
    .wp-element-button {


      &:hover {

      }
    }
  }
}
