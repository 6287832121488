@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;

.featured-products {
  .products-block-post-template {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .wc-block-components-product-price {
    color: var(--wp--preset--color--primary-light);
    font-size: var(--wp--preset--font-size--font-editor-1);
    font-weight: 300;
    line-height: 1em;
    text-decoration: none;
    margin: 8px 0;

    del {
      display: none;
    }
  }

  .wp-block-button {
    margin-top: auto;
  }

  @include breakpoint(md) {
    .products-block-post-template {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    .wc-block-components-product-price {
      margin: 18px 0;
    }
  }

  @include breakpoint(lg) {
    .products-block-post-template {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}