@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;
@use "../../abstracts/svg" as *;

.practice-quiz-form {
  .gform_footer {
    margin-top: 20px !important;
    margin-bottom: 32px !important;
    padding: 0 !important;
  }

  input[type="submit"] {
    margin-bottom: 0px !important;
  }
}