@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.page-template-practice-test-passed {
  // Page Wrapper
  .page-wrapper {
    max-width: 100%;
    padding-top: $primary-top-bottom-spacing !important;
    padding-bottom: $primary-top-bottom-spacing !important;

    > .wp-block-columns {
      gap: 40px !important;

      @include breakpointMax(md) {
        gap: 0px !important; 
      }
    }
  }

  // Page Left Column
  .hero {
    &__title {

    }

    &__text {

    }

    &__offer-text {

    }
  }

  .product-offer {
    &__card {

    }
  }

  // Page Right Column
  .sidebar {
    &__title {
      
    }

    &__text {
      
    }
  }

  // Page Trustpilot
  .trustpilot-container {

  }
}