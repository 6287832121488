@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;

.post-grid-primary {
  position: relative;

  &__img img {
    height: 100% !important;
    max-height: 280px;
    object-fit: cover;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    flex-direction: row!important;
    justify-content: center;
  }

  &__title {
    max-height: calc(100% - 100px);
    overflow: hidden;

    a {
      color: inherit;
    }
  }

  &__read-more {
    @include button-primary;
    margin-top: auto !important;
    min-width: 210px;
    padding: 12px 32px;
    border-radius: 1000px;
    text-align: center;
    font-weight: 400;

    @media (min-width: 600px) and (max-width: 1023px) {
      min-width: auto;
    }
  }

  @include breakpoint(md){
    height: 365px;
    &__img img {
      max-height: 100%;
    }
  }
}
