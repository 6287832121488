@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.page-template-algorithms{
  .hero{
    padding-top: $primary-top-bottom-spacing !important;
    padding-bottom: $primary-top-bottom-spacing !important;
  }

  .algorithms-grid{

  }

  .algorithms-card{

  }
}