@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;


.testimonial-card {
  height: 100%;

  &__content {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  &__author {
    margin-top: auto;
  }
}

.trustpilot-testimonials__slider {
  iframe {
    @include breakpointMax(md) {
      height: 300px !important;
    }
  }
}

.trustpilot-slider {

  .swiper-carousel .swiper-slide {
    margin-bottom: initial;
    margin-right: initial;
  }
  
  @media (min-width: 600px) {
    .wp-block-column:first-of-type{
      flex-basis: 20% !important;
    }
    .wp-block-column:last-of-type{
      flex-basis: 80% !important;
    }
  }
}
