@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;

.product-card-with-category {
  &__category {
    position: absolute;
    width: auto !important;
    left: 16px;
    top: 0;
    margin: 0;
    padding: 0;
    line-height: 1;
    background: var(--wp--preset--color--white);
    transform: translateY(-50%);
  }

  @include breakpointMax(md) {
    margin-top: 40px !important;

    &__heading {
      font-size: 22px !important;
    }
  }
}