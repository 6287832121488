@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;


.mini-card {
  padding: 48px 24px !important;

  .wp-block-woocommerce-filled-mini-cart-contents-block {
    justify-content: flex-start;
  }

  .wp-block-woocommerce-mini-cart-title-block {
    margin: 0;
    padding-bottom: 8px;
    -webkit-mask-image: none;
  }

  .wp-block-woocommerce-mini-cart-title-items-counter-block {
    display: none;
  }

  .wc-block-components-drawer__close-wrapper {
    position: absolute;
    top: 15px;
    right: 15px;

    .wc-block-components-button {
      padding: 0 !important;
      border: none;
      min-width: auto;
    }
  }

  .wc-block-mini-cart__items {
    padding: 0;
    margin-bottom: 0;
    flex-grow: 0;
  }

  /* Product Item */

  .wc-block-components-product-metadata,
  .wc-block-cart-item__quantity,
  .wc-block-cart-item__total,
  .wc-block-components-product-badge,
  .wc-block-cart-items__row.is-bundled__indented {
    display: none !important;
  }

  .wc-block-cart-items__row {
    padding: 12px 0 0 !important;
    margin: 0 0 10px 0;
    border-top: dashed 1px var(--wp--preset--color--grey-medium);
    grid-template-columns: 60px auto !important;
  }

  .wc-block-cart-item__image {
    padding-right: 8px !important;
  }

  .wc-block-cart-item__product {
    padding: 0 !important;
  }

  .wc-block-components-product-name {
    text-decoration: none;
    margin-bottom: 4px;
  }

  .price {
    font-size: 12px;
    color: var(--wp--preset--color--grey-light-primary);
  }

  /* Mini-card Footer */
  .wc-block-mini-cart__footer {
    padding: 0;

    &:after {
      display: none;
    }
  }

  .wc-block-components-totals-item {
    margin-bottom: 16px;

    &__description {
      display: none !important;
    }
  }

  .wc-block-components-totals-item {
    justify-content: flex-start;
    gap: 5px;

    &__label {
      flex-grow: 0;
    }

    &__value {
      font-weight: 400;
    }
  }

  .wc-block-mini-cart__footer-actions {
    .wp-element-button {
      max-width: 200px;
      margin: auto;

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

@include breakpoint(md) {
  .wc-block-mini-cart__drawer {
    max-width: 400px;
  }
  .mini-card {
    .wc-block-mini-cart__footer-actions {
      .wp-element-button {
        min-width: auto;
        width: 100%;
      }
    }
  }
}