@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;

.related-products {
  .products-block-post-template {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  &__item-container {
    height: 100%;
    gap: 0;
  }

  figure {
    img {
      width: auto;
      height: 210px;
      object-fit: contain !important;
      object-position: top center;
      opacity: 1;
      transition: $primary-animation;
    }

    a:hover {
      img {
        opacity: .7;
      }
    }
  }

  .wp-block-post-title {
    margin-bottom: 10px;
    a {
      color: var(--wp--preset--color--primary-dark);

      &:hover {
        color: var(--wp--preset--color--primary-light);
      }
    }
  }

  .wc-block-components-product-price {
    color: var(--wp--preset--color--secondary);
    line-height: 1em;
    text-decoration: none;

    del {
      display: none;
    }
  }

  .wp-block-button {
    margin-top: auto;
  }

  @include breakpoint(md) {
    .products-block-post-template {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    //.wc-block-components-product-price {
    //  margin: 18px 0;
    //}
  }

  @include breakpoint(lg) {
    .products-block-post-template {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}