@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;


.single-post {
  background: linear-gradient(180deg, var(--wp--preset--color--secondary) 0%, var(--wp--preset--color--secondary) 293px, var(--wp--preset--color--white) 293px, var(--wp--preset--color--white) 100%);

  > div{
    border: none!important;
  }

  &__author{
    justify-content: center;
    align-items: center!important;
    row-gap: 16px;
    flex-direction: column;
    *{
      justify-content: center;
    }
    &-avatar{
      .wp-block-post-author__avatar{
        margin-left: 0;
        margin-right: 0;
        width: 100px;
        height: 100px;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
        }
      }
      .wp-block-post-author__content{
        display: none;
      }
    }
  }

  .author-name{
    color: var(--wp--preset--color--primary);
    font-size: var(--font-3);
    font-weight: 400;
    line-height: 1em;
    margin-bottom: 8px;
    text-align: center;

    &:before{
      content: 'by';
      display: inline-block;
      margin-right: 5px;
    }
  }

  &__content {
    h2, h3, h4, h5 {
      margin-bottom: 8px;
    }

    h2{
      color: var(--wp--preset--color--primary-dark);
    }

    h3, h4, h5 {
      color: var(--wp--preset--color--primary);
    }

    p, ul {
      padding-bottom: 14px !important;
    }

    ul, ol {
      margin-top: 0;
      padding-left: 16px;

      ::marker {
        font-size: inherit;
      }
      
      a{
        text-decoration: underline;
      }
    }

    ol {
      li {
        margin-bottom: 14px;
      }
    }

    li {
      ol, ul {
        margin-top: 14px;
      }
    }

    img{
      padding-bottom: 20px;
      display: block;
      margin: auto;
    }
  }

  &__nav {
    .is-arrow-arrow {
      color: var(--wp--preset--color--grey-primary);
    }

    a {
      text-decoration: none;
      &:hover{
        color: var(--wp--preset--color--primary-dark);
      }
    }
  }

  .wp-block-comments{
    h2{
      color: var(--wp--preset--color--primary-dark);
      margin-top: 0;
    }
  }

  .wp-block-comment {

    &-title {
      color: var(--wp--preset--color--primary);
    }

    &-author-name {
      font-style: italic;
    }

    &-reply-link {
      a {
        font-size: 12px;
      }
    }
  }

  &__comments-form {
    .comment-reply-title {
      color: var(--wp--preset--color--primary);
      font-size: var(--font-3);
      margin-bottom: 8px;
    }

    label{
      display: block;
    }

    textarea, input:not([type="submit"]){
      @include form-field;
      border: 1px solid var(--wp--preset--color--grey-medium)!important;

      &:focus {
        border-color: var(--wp--preset--color--grey-light-primary)!important;
      }
    }

    .logged-in-as{
      display: flex;
      flex-direction: column;

      a:nth-of-type(2){
        margin-top: -22px;
      }
    }
  }

  .cta_sidebar{
    position: sticky;
    left: 0;
    top: 130px;
    padding: 16px;
    background-color: var(--wp--preset--color--grey-light-secondary);


    &__title{
      color:var(--wp--preset--color--primary);
      font-size: var(--font-5);
      text-align: center;
      margin-bottom: 16px;
      margin-top: 0;
    }

    &__container{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      div{
        max-width: 310px;
        width: 100%;
        margin-bottom: 16px;
        &:last-of-type{
          margin-bottom: 0;
        }
      }
    }

    .button-primary{
      @include button-primary;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      line-height: 1;
      height: 44px;
      padding: 12px;
      font-size: 16px;
      font-weight: 400;
      color: var(--wp--preset--color--white);
      background: var(--wp--preset--color--secondary-dark);
      border: 1px solid var(--wp--preset--color--secondary-dark);
      border-radius: 50px;
      box-shadow: none;
      transition: all 0.3s ease;
      opacity: 1;
      outline: none;
      br{
        display: none;
      }
    }
  }

  .author_bio_section {
    border: none;
    background-color: var(--wp--preset--color--grey-medium) !important;
    border-radius: 5px;
    font-size: 1em;
    padding: 28px 28px;
    word-wrap: break-word;

    .author_name {
      color: var(--wp--preset--color--primary-dark) !important;
      text-transform: capitalize;
      font-size: var(--font-2) !important;
      font-weight: 400 !important;
      line-height: 1em !important;
    }

    .author_details img {
      display: none !important;
    }

  }

  @include breakpoint(md) {
    &__author{
      justify-content: flex-start;
      flex-direction: row;
      *{
        justify-content: flex-start;
      }
    }

    .author-name{
      text-align: left;
    }

    &__content {
      img{
        padding-bottom: 0;
        &.alignright{
          float: right;
          padding-left: 20px;
        }

        &.alignleft{
          float: left;
          padding-right: 20px;
        }
      }
    }
  }
}
