@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.about-hero {

  @media (max-width: 1100px) {
    .certifications__container {
      display: grid !important;
      grid-template-columns: repeat(2, 1fr);
      flex-wrap: wrap;
    }
  }
  @include breakpointMax(md) {
    .certifications__container {
      display: flex !important;
    }
  }
}
