@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;


.page-template-bundle-product{
  .gform_wrapper input[type=submit]{
    width: 264px;
    height: 44px;
  }

  .bundle-products{

  }

  .certification-courses{

  }

  .faq-tabs{

  }
}