@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.accordion-primary {
  margin: 0;

  .wp-block-custom-accordion-item {
    margin-bottom: 40px;
    background: transparent !important;
    border: 0 !important;

    &:last-child {
      margin-bottom: 0;
    }

    .ac-trigger {
      display: inline-flex !important;
      align-items: flex-start;
      padding: 0 0 0 34px !important;
      position: relative;
      font: 300 var(--wp--preset--font-size--font-4) var(--wp--preset--font-family--font-body) !important;

      &:after {
        content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 5V19' stroke='%2300B4DC' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5 12H19' stroke='%2300B4DC' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
        top: 0 !important;
        left: 0 !important;
        right: auto !important;
        width: 24px !important;
        height: 24px !important;
        transform: none !important;
        position: absolute;
      }
    }

    .ac-panel {
      padding: 10px 0 0 34px !important;
      overflow: hidden;
    }

    &.is-active {
      .ac-trigger {
        &:after {
          content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 12H19' stroke='%2300B4DC' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
        }
      }
    }
  }

  @include breakpoint(lg) {
    .wp-block-custom-accordion-item {
      margin-bottom: 32px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &_small-spaces {
    .wp-block-custom-accordion-item {
      margin-bottom: 8px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.accordion-single {
  margin: 0;

  .wp-block-custom-accordion-item {
    text-align: center;
    border: 0 !important;

    .ac-trigger {
      display: inline-flex !important;
      align-items: center;
      padding: 0 20px 0 0 !important;
      color: var(--wp--preset--color--grey-light-primary);
      font: 400 var(--font-body) var(--wp--preset--font-family--font-body) !important;

      &:after {
        content: "" !important;
        left: auto !important;
        right: 0 !important;
        width: 12px !important;
        height: 12px !important;
        background: url("data:image/svg+xml,%3Csvg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.3694 10.9898C6.71817 11.3386 7.28458 11.3386 7.63335 10.9898L12.0976 6.52553C12.4464 6.17676 12.4464 5.61035 12.0976 5.26158C11.7489 4.91281 11.1825 4.91281 10.8337 5.26158L6.99998 9.09528L3.16628 5.26437C2.8175 4.9156 2.2511 4.9156 1.90233 5.26437C1.55355 5.61314 1.55355 6.17955 1.90233 6.52832L6.36661 10.9926L6.3694 10.9898Z' fill='black'/%3E%3C/svg%3E%0A") no-repeat!important;
        background-size: contain;
      }
    }

    .ac-panel {
      padding: 11px 0 0 0 !important;
      overflow: hidden;
    }

    &.is-active {
      .ac-trigger {
        &:after {
          transform: rotate(180deg) translateY(25%);
        }
      }
    }
  }
}

.accordion-module {
  .wp-block-custom-accordion-item {
    border: none!important;

    &.ac{
      margin-top: 0;
    }

    .ac-header{
      padding: 16px 20px!important;
      background-color: var(--wp--preset--color--grey-extra-light);
      cursor: pointer;
      border-bottom: 1px solid var(--wp--preset--color--grey-secondary);
      color: var(--wp--preset--color--primary-dark);
      font: 400 var(--font-4) var(--wp--preset--font-family--font-body) !important;
      &:hover{
        background-color: var(--wp--preset--color--grey-light-secondary);
      }

      &:after{
        display: none;
      }
    }
  }

  &__lessons{
    @include lessons-list-item;
    a{
      border-width: 1px!important;
    }
  }
}