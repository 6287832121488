/* Media Query breakpoints
--------------------------------------------- */
$breakpoints: (
	xs: 425px,
	xss: 500px,
	md: 782px,
	lg: 992px,
	xl: 1280px,
	hd: 1920px
);

/* Columns
--------------------------------------------- */
$columns: (
	1: 100%,
	2: 50%,
	3: 33.33%,
	4: 25%,
	5: 20%,
	6: 16.66%,
	7: 14.28%,
	8: 12.5%,
	9: 11.11%
);

/* UI
--------------------------------------------- */
$primary-animation: all .3s ease;
$scroll-top-offset: 100px;
$primary-top-bottom-spacing: 36px;
