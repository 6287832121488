@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;
@use "../../abstracts/svg" as *;

main, .primary-popup{
  form {
    font-family: var(--wp--preset--font-family--font-body) !important;
    font-size: var(--font-body) !important;
    font-weight: 400 !important;
    color: var(--wp--preset--color--grey-light-primary) !important;

    label, legend {
      font-weight: 700 !important;
      margin: 0 0 12px 0 !important;
    }

    fieldset {
      margin: 0;
      padding: 0;
      border: 0;
    }

    input[type="tel"] {

      @include form-field-tel;

      width: 100%;
      height: 54px;
    }

    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    input[type="date"],
    input[type="datetime-local"],
    input[type="search"],
    input[type="url"] {

      @include form-field;

      width: 100%;
      height: 54px;
    }

    input[type="number"] {
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    input[type="file"] {
      font-size: 12px !important;
    }

    input::file-selector-button {
      //@include form-field;
      //@include background-svg('<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_5356_52743)"><mask id="mask0_5356_52743" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25"><path d="M24 0.870605H0V24.8706H24V0.870605Z" fill="#333333"/></mask><g mask="url(#mask0_5356_52743)"><path fill-rule="evenodd" clip-rule="evenodd" d="M21 12.1206C21.4142 12.1206 21.75 12.4564 21.75 12.8706V21.8706C21.75 22.2848 21.4142 22.6206 21 22.6206H3C2.58579 22.6206 2.25 22.2848 2.25 21.8706V12.8748C2.25 12.4605 2.58579 12.1248 3 12.1248C3.41421 12.1248 3.75 12.4605 3.75 12.8748V21.1206H20.25V12.8706C20.25 12.4564 20.5858 12.1206 21 12.1206Z" fill="#1C1B1E"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.4697 3.34028C11.7626 3.04738 12.2374 3.04738 12.5303 3.34028L17.0303 7.84028C17.3232 8.13317 17.3232 8.60804 17.0303 8.90094C16.7374 9.19383 16.2626 9.19383 15.9697 8.90094L12 4.93127L8.03033 8.90094C7.73744 9.19383 7.26256 9.19383 6.96967 8.90094C6.67678 8.60804 6.67678 8.13317 6.96967 7.84028L11.4697 3.34028Z" fill="#1C1B1E"/><path fill-rule="evenodd" clip-rule="evenodd" d="M11.9958 3.12061C12.4101 3.12061 12.7458 3.45639 12.7458 3.87061V16.8706C12.7458 17.2848 12.4101 17.6206 11.9958 17.6206C11.5816 17.6206 11.2458 17.2848 11.2458 16.8706V3.87061C11.2458 3.45639 11.5816 3.12061 11.9958 3.12061Z" fill="#1C1B1E"/></g></g><defs><clipPath id="clip0_5356_52743"><rect width="24" height="24" fill="white" transform="translate(0 0.870605)"/></clipPath></defs></svg>');

      padding-left: 68px !important;
      padding-right: 28px !important;
      margin-right: 10px;
      font-size: 14px !important;
      background-repeat: no-repeat;
      background-size: 24px;
      background-position: 28px 50%;
      border-radius: 0;
    }

    input[type="search"] {

      &::-webkit-search-cancel-button {
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
        background-size: 10px 10px;
        height: 10px;
        width: 10px;
        appearance: none;
        -webkit-appearance: none;
      }
    }

    input[type="checkbox"] {

      @include form-checkbox;
    }

    input[type="radio"] {
      @include form-radio;
    }

    select {

      @include form-select;

      width: 100%;
      height: 54px;
    }

    textarea {

      @include form-field;

      width: 100%;
      resize: none;
    }

    button[type="submit"],
    input[type="submit"],
    .gform-body .gform_page_footer .gform_previous_button.button,
    .woocommerce input.button,
    .button {
      @include form-button;
      min-width: 180px;
    }

    p {
      color: var(--wp--preset--color--grey-light-primary) !important;
    }
  }
}

