@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.price-with-discount {
  .wc-block-components-product-price {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    del{
      margin-left: 4px;
    }

    ins{
      color: var(--wp--preset--color--secondary);
    }
  }
}