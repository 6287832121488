@use "../base/typography" as *;

.annual-survey {
  .gform_wrapper {
    h1 {
      color: var(--wp--preset--color--primary-dark);
      font-size: var(--font-1);
      margin-top: 0;
      margin-bottom: 8px;
    }
    p{
      padding-bottom: 0;
    }

    .annual-survey-form__title{
      margin-bottom: -18px!important;
    }

    .gsection{
      margin: 16px 0 4px!important;
      .gsection_title{
        margin: 0;
      }
    }

    .gform-field-label--type-sub{
      line-height: 1.625;
    }
  }
}


.annual-survey-social {
  gap: 12px;

  .wp-social-link svg {
    height: 24px !important;
    width: 24px !important;
  }
}
