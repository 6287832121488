@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.table-primary {
  @include table-primary;
}

.table-primary-invert {
  tbody {
    tr:nth-child(odd) {
      background: var(--wp--preset--color--grey-light-secondary);
    }

    tr:nth-child(even) {
      background: var(--wp--preset--color--white);
    }
  }
}
