@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;

//Step Number
$stepNumberColor: var(--wp--preset--color--primary-light);
$stepNumberBorderColor: var(--wp--preset--color--grey-secondary);
$stepNumberFontSize: 28px;
$stepNumberFontWeight: 700;

//Step Content
$stepContentTitleColor: var(--wp--preset--color--primary);
$stepContentTitleSize: 20px;
$stepContentButtonColor: var(--wp--preset--color--white);
$stepContentButtonBgColor: var(--wp--preset--color--secondary-dark);
$stepContentButtonBorderColor: var(--wp--preset--color--secondary-dark);
$stepContentButtonColorHover: var(--wp--preset--color--white);
$stepContentButtonBgColorHover: var(--wp--preset--color--secondary);
$stepContentButtonBorderColorHover: var(--wp--preset--color--secondary-dark);


.free-course {

  &__step-item {
    justify-content: center;
    gap: 16px;
    flex-direction: column;

    &__number {
      border: 5px solid $stepNumberBorderColor;
      margin: 0 auto;
      border-radius: 50%;
      width: 50px;
      height: 50px;
      text-align: center;
      color: $stepNumberColor;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        padding-bottom: 0;
        font-size: $stepNumberFontSize;
        font-weight: $stepNumberFontWeight;
      }
    }

    &__content {
      justify-content: center;
      text-align: center;
      flex-direction: column;
      max-width: 300px;
    }

    &__title {
      color: $stepContentTitleColor!important;
      font-size: $stepContentTitleSize;
    }

    &__button{
      .wp-element-button{
        color: $stepContentButtonColor;
        background-color: $stepContentButtonBgColor;
        border-color: $stepContentButtonBorderColor;
        &:hover{
          color: $stepContentButtonColorHover;
          background-color: $stepContentButtonBgColorHover;
          border-color: $stepContentButtonBorderColorHover;
        }
      }

    }
  }

  @include breakpoint(md) {

    &__step-item {
      flex-wrap: nowrap !important;
      align-items: flex-start !important;
      flex-direction: row;

      &__number {
        flex-shrink: 0;
      }

      &__content {
        justify-content: flex-start;
        text-align: left;
        align-items: flex-start !important;
      }
    }
  }
}