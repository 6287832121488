@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.page-template-free-certifications {
  // For All Pages
  .hero {
    padding-top: $primary-top-bottom-spacing !important;
    padding-bottom: $primary-top-bottom-spacing !important;

    &__title {
      
    }

    &__text {
      
    }
  }
  
  // For Free Certifications Pattern
  .free-certifications {
    max-width: 100%;

    .hero {
      &__logos-container {

      }

      &__logo {
        padding: 8px;
        border: 1px solid var(--wp--preset--color--grey-medium) !important;
        border-radius: 50%;

        img {
          width: 56px;
          height: 56px;
          object-fit: cover;
          border: 1px solid var(--wp--preset--color--grey-medium) !important;
          border-radius: 50%;
        }
      }

      &__title {
        
      }

      &__subtitle {

      }

      &__separator {
      
      }

      &__text {
        
      }

      &__text-secondary {

      }

      &__text-with-link {

      }

      &__arrow {

      }

      .notification-box {

      }
    }

    .content {
      max-width: 100%;

      &__container {

      }
    }

    .certifications {

    }

    .certification-card {
    
    }

    .certification-benefits {
      
    }

    .choose-course {
      
    }
  }

  // For Free Life Saving Certifications Pattern
  .free-life-saving-certifications  {
    max-width: 100%;

    .hero {
      max-width: 100%;
      
      &__container {
        
      }

      &__title {
        
      }
    }

    .notification-bar {
      max-width: 100%;

      &__container {

      }

      &__text {

      }
    }

    .content {
      max-width: 100%;

      &__container {

      }

      .certifications {

        &_secondary {

        }

      }
    
      .certification-card {
    
      }

      &__text {

      }

      .notification-box {

      }
    }

    .benefits {
      max-width: 100%;

      .certification-benefits {
      
      }
    }
  }

  // For Certification Course Pattern
  .certification-courses {
    max-width: 100%;

    .hero {
      &__title {
    
      }
  
      // Anchors
      .navigation {
        @include breakpointMax(md) {
          justify-content: center !important;
          margin-top: 20px;
          padding-bottom: 0 !important;
        }
      }
  
      @include breakpointMax(md) {
        &__title {
          text-align: center;
        }
      }
    }
  
    .certification-courses {
      position: relative;
      max-width: 100%;
  
      &::before {
        content: ' ';
        position: absolute;
        display: block;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        background: var(--wp--preset--color--primary-light);
      }
  
      &__container {
  
      }
    
      @include breakpointMax(md) {
        &::before {
          top: 55%;
        }
      }
    }
    
    .certification-courses-grid {
      @include breakpointMax(md) {
        &:last-child {
          margin-top: 0px !important;
        }
    
        .product-card-with-category__category {
          width: calc(100% - 60px) !important;
          left: 50%;
          text-align: center;
          transform: translate(-50%, -50%);
        }
    
        .product-card-with-category:first-child {
          margin-top: 0px !important;
        }
      }
    }
  
    .faq {
      
    }
  }

  // For Choose Free Course Pattern
  .choose-free-course {
    max-width: 100%;

    .hero {
      &__title {

      }

      &__subtitle {

      }

      &__text {

      }

      .choose-courses {

      }

      .train-width-us-container {
        p {
          color: var(--wp--preset--color--grey-light-primary) !important;
        }
      }

      .benefits-container {

      }
    }

  }

  // For Steps Posters Pattern
  .steps-posters {
    max-width: 100%;

    .poster-hero {
      &__title {

      }

      &__text {

      }

      &__buttons-container {

      }


    }

    .content {
      &__container {

      }
    }

    .hero {
      &__title {
    
      }
    }

    .certifications {
      &_secondary {

      }
    }
  
    .certification-card {
  
    }

    .benefits {
      .certification-benefits {
      
      }
    }
  }
}