@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.page-template-megacodes-scenarious {
  // Page Wrapper
  .page-wrapper {
    padding-top: $primary-top-bottom-spacing !important;
    padding-bottom: $primary-top-bottom-spacing !important;
    
    &__title {

    }

    &__text {

    }
  }
}