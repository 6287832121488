@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

/* Restyle Core Styles */
html {
  scroll-behavior: smooth;
}

body{
  padding-top: 84px;
}

.wp-site-blocks{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main{
  flex-grow: 1;
}

a {
  transition: $primary-animation;
}

ul {
  padding-left: 12px;

  ::marker {
    font-size: .6em;
  }

  a {
    text-decoration: none;
  }

  li {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    > *{
      line-height: 1.4;
    }
  }
}

p:not(p+p){
  padding-bottom: 0;
}

p:first-child {
  padding-bottom: 14px;
}

.wc-block-components-product-button {
  a {
    padding: 12px 24px !important;
  }
}

img {
  max-width: 100%;
}

.wp-block-cover,
.wp-block-cover-image {
  min-height: auto;
}

.wp-block-template-part,
.wp-block-post-content {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.wp-element-button{
  min-width: 180px;

  s {
    padding: 0 5px;
  }
}

.swiper-carousel{
  margin-top: 0;

  .swiper-slide{
    margin-bottom: 0!important;
  }
}

.page-template:not(.page-id-11849115){
  .swiper-carousel{
    margin-top: 0;

    .swiper-slide{
      margin-right: 0!important;
    }
  }
}

main .woocommerce{
  max-width: var(--wp--style--global--content-size)!important;
}

.wp-block-table{
  @include table-primary;
  margin-bottom: 16px;
  overflow: hidden;

  figcaption{
    margin-top: 8px;
  }
}

.wp-block-quote{
  border: none;
  padding: 0 3rem 1rem 3rem;
  position: relative;
  color: var(--wp--preset--color--primary);

  *{
    color: var(--wp--preset--color--primary);
    font-size: 22px;
    line-height:  1.4rem;
    font-weight: 700;
  }
  &:before{
    content: '';
    height: 0.3rem;
    background-color: var(--wp--preset--color--primary);
    width: 90%;
    position: absolute;
    bottom: 20px;
    border-radius: 10px;
  }

  @media (max-width: 781px) {
    & {
      padding: 0 0 1rem 0;
    }
  }

}

.wp-block-quote--list {
  .wp-block-heading {
    &::before {
      content: "• ";
      font-size: 30px;
      margin-top: -4px;
    }

    display: grid;
    grid-template-columns: 16px 1fr;
    
    em {
      grid-column: 2;
    }
  }
}

.wp-block-image, .wp-block-gallery, .wp-block-table{
  figcaption{
    color: var(--wp--preset--color--primary);
    font-style: italic;
  }

  .wp-block-image, .wp-block-gallery{
    margin-bottom: 16px;
  }
}

.wp-block-gallery{
  margin-bottom: 16px;
  gap: 16px;
  &:last-child{
    margin-bottom: 0;
  }

  .wp-block-image{
    flex-grow: 0!important;
  }

  figcaption{
    margin-top: -10px;
  }

  &.has-nested-images figure.wp-block-image figcaption{
    color: var(--wp--preset--color--primary-dark);
    font-style: italic;
    position: initial;
    background: none;
  }
}


/* Custom Classes */

body.lock {
  overflow: hidden;
}

.full-width{
  width: 100% !important;

  img{
    width: 100%;
  }
}

.full-height {
	height: 100% !important;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.center-horizontal-alignment {
  justify-content: center;
}

.row-mobile-wrap {
  @include breakpointMax(md) {
    flex-wrap: wrap!important;
  }
}

.list-secondary{
  ::marker {
    font-size: inherit;
  }
}

.link-red{
  a{
    color: var(--wp--preset--color--secondary);
  }
}

.link-text-decoration-none{
  a{
    text-decoration: none;
  }
}

.text-centered-mobile{
  @include breakpointMax(md){
    margin: auto;
    & *{
      text-align: center!important;
      justify-content: center!important;
    }
  }
}

.padding-horizontal-0-mobile{
  @include breakpointMax(md){
    padding-left: 0!important;
    padding-right: 0!important;
  }
}

.margin-top-auto{
  margin-top:auto!important;
}

.flex-grow-1{
  flex-grow: 1;
}

.text-nowrap{
  white-space: nowrap;
}
.hide-mobile{
  @include breakpointMax(md){
    display: none!important;
  }
}

@include breakpoint(md){
  body{
    padding-top: 88px;
  }
}
