@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

//Hero Heading

$heroTitleColor: var(--wp--preset--color--primary-dark);
$heroTitleFontSize: var(--font-1);
$heroTitleFontWeight: 700;

//Hero Text
$heroTextColor: var(--wp--preset--color--grey-primary);
$heroTextFontSize: var(--font-body);
$heroTextFontWeight: 400;

.megacode-scenario {
  padding-top: $primary-top-bottom-spacing !important;
  &+div{
    >.related-products{
      margin-bottom: $primary-top-bottom-spacing !important;
    }
  }
}

.megacode-template {
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .hero {
    &__title {

    }

    &__text {

    }
  }

  .megacode-card{
    &__title{

    }

    &__subtitle{
      font-size: var(--font-4)!important;
    }

    &__text{

    }

    &__button{
      .wp-element-button{


        &:hover{

        }
      }
    }
  }

  .related-products__heading{

  }

  .related-products{
    &__title{

    }

    &__price{

    }

    &__button{
      .wp-element-button{

        &:hover{

        }
      }
    }
  }

  .socialShare .a2a_kit{
    flex-wrap: wrap;
    row-gap: 2px;
  }
}
