@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.megacode-scenario {
  &__heart-icon {
    width: 60px !important;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
    margin-bottom: -30px !important;
  }

  &__bottom-card {
    &-title {
      margin-top: -20px !important;
    }
  }

  &__card-wrapper {
    > .wp-block-columns {
      flex-wrap: wrap !important;

      .wp-block-column {
        flex-basis: auto;

        &:empty {
          flex-basis: auto;
        }

        &:first-child:nth-last-child(3),
        &:first-child:nth-last-child(3) ~ .wp-block-column {
          flex-basis: 100%;
        }
      }
    }

    @include breakpointMax(md) {
      padding-bottom: 0 !important;
    }

    @media (min-width: 1100px) {
      > .wp-block-columns {
        .wp-block-column {
          flex-basis: 0;

          &:first-child:nth-last-child(3),
          &:first-child:nth-last-child(3) ~ .wp-block-column {
            flex-basis: 0;
          }
        }
      }
    }
  }

  &__testimonials {
    @include breakpointMax(md) {
      margin-top: -35px !important;
    }
  }

  .additional-questions-form {
    input[type=submit] {
      max-width: 220px;
    }
  }
}