@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

footer {
  margin: 0;
}

.footer-main {
  a {
    text-decoration: none;
    color: var(--wp--preset--color--white);

    &:hover {
      color: var(--wp--preset--color--primary-light);
    }
  }

  figure {
    a {
      &:hover {
        opacity: .7;
      }
    }
  }

  &__social-icons {
    flex-wrap: wrap!important;
    .wp-block-outermost-icon-block {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @include breakpointMax(md) {
    &__separator {
      display: none;
    }

    &__social-icons {
      margin-bottom: 32px !important;
    }
    
    &-logos {
      justify-content: flex-start !important;
      column-gap: 32px;
      row-gap: 32px;
    }
  }
}

@media (max-width: 950px) and (min-width: 782px) {
  .wp-block-group.footer-main-logos {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
  }
  .footer-trustpilot {
    flex-wrap: wrap !important;
  }
}