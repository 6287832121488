.primary-pagination{
  a{
    color: var(--wp--preset--color--grey-primary);
    text-decoration: none;
  }

  .wp-block-query-pagination{
    &-previous,
    &-next,
    &-numbers{
      margin: 0;
      &-arrow{
        margin: 0;
      }
    }

    &-numbers{
      display: flex;
      gap: 16px;
    }
  }
}