@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.page-template-single-lesson {
  padding: 20px 20px 36px!important;

  > .wp-block-post-content > .wp-block-group {
    padding: 0 !important;
  }

  .single-lesson__container {
    @include breakpointMax(md) {
      flex-direction: column-reverse;
    }
  }

  .single-lesson__content {
    p {
      padding-bottom: 16px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    .wp-block-heading {
      margin-bottom: 14px;

      strong{
        font-weight: inherit!important;
      }
    }

    .has-inline-color.has-primary-dark-color{
      color: var(--wp--preset--color--primary)!important;
    }

    ul, ol {
      margin-top: 0;
      padding-left: 32px;
      margin-bottom: 16px;

      ::marker {
        font-size: inherit;
      }

      li {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        margin-top: 16px;

        li {
          list-style-type: disc;
        }
      }
    }

    &__title {

    }
  }

  .single-lesson__sidebar {

    &-title {

    }

    &-progress-navigation {

    }

    .accordion-module {
      .wp-block-custom-accordion-item {

        .ac-header {

        }
      }

      &__lessons {

      }
    }
  }

  @media (max-width: 430px) {
    .single-lesson__content ~ .wp-block-group .wp-block-buttons {
      justify-content: center !important;
    }
  }

  @media (max-width: 600px) {

    .single-lesson__content {

      .wp-block-gallery {
        .wp-block-image {
          width: 100% !important;
          max-width: 300px;
          margin: auto !important;
        }
      }
    }
  }

  @include breakpointMax(md) {
    .single-lesson__content {
      max-width: calc(100vw - 40px);

      .wp-block-table {
        overflow-x: auto;

        table {
          table-layout: auto;
        }
      }
    }
  }
}