@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.order-confirmation {
  .woocommerce {
    max-width: 100%;
  }

  h1 {
    margin-top: 0;
    font-weight: 700;
    font-size: var(--font-1) !important;
    color: var(--wp--preset--color--primary-dark);
    text-align: center;

    ~ p {
      text-align: center;

      a {
        @include button-primary;
        border-radius: 50px;
        font-size: 16px;
        font-weight: 400;
        line-height: inherit;
        padding: 12px 24px;
        text-decoration: none;
        display: inline-block;
      }
    }
  }

  h2 {
    margin-top: 0;
    margin-bottom: 20px;
    color: var(--wp--preset--color--primary-dark);
    font-size: var(--font-2) !important;
    font-weight: 400 !important;
  }

  .woocommerce-notice {
    border: 2px solid var(--wp--preset--color--blue-light-primary);
    border-radius: 5px;
    padding: 25px;
    background: var(--wp--preset--color--grey-light-secondary);
    margin-bottom: 30px;
    text-align: center;
    font-size: var(--font-body);

    &.woocommerce-notice--error {
      border-color: var(--wp--preset--color--secondary);
    }

    &:not(:has(>*)) {
      display: none;
    }

    h1 {
      margin-top: 0;
      margin-bottom: 16px;
      text-align: center;
      line-height: 1em;
      font-size: var(--font-1);
      color: var(--wp--preset--color--primary-dark);
    }

    .thanks_get_started {
      @include button-primary;
      border-radius: 50px;
      font-size: 16px;
      font-weight: 400;
      line-height: inherit;
      padding: 12px 24px;
      text-decoration: none;
      display: inline-block;
    }

    p {
      margin: 0;

      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }

  .woocommerce-order-overview {
    justify-content: flex-start;
    column-gap: 0;
    margin-bottom: 20px !important;

    li {
      font-weight: 400;
      text-transform: uppercase;
      font-size: 12px !important;
      flex-grow: 0 !important;
      float: none;

      strong {
        font-weight: 700 !important;
        text-transform: none;
        font-size: 16px !important;
      }
    }
  }

  .woocommerce-order-downloads {
    display: none;
  }

  .cross-sells {
    margin-bottom: 32px;

    .products {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      row-gap: 20px;

      &:before {
        display: none;
      }

      @include cross-sale-card;

      br {
        display: none;
      }

      .price {
        margin-bottom: 0 !important;
      }

      .product {
        width: 100% !important;
        float: none !important;
        margin: 0 !important;

        > p {
          margin-top: auto;
        }

        .button {
          margin-top: 20px;
        }
      }
    }


  }

  .shop_table {
    @include products-table;
    margin-bottom: 24px !important;

    .product-name {
      padding-left: 16px !important;

      a {
        padding-bottom: 0 !important;
        color: var(--wp--preset--color--primary) !important;
        text-decoration: underline !important;
        height: auto !important;

        &:hover {
          color: var(--wp--preset--color--primary-light) !important;
        }
      }
    }

    th, td {
      padding: 9px 12px !important;
    }

    th:nth-child(2), td:nth-child(2) {
      text-align: right !important;

      span {
        justify-content: flex-end;
      }
    }

    tbody{
      .product-total *{
        font-weight: 400;
      }
    }

    tfoot {
      th {
        font-weight: 400 !important;
      }

      tr:not(:last-of-type) {
        td {
          font-weight: 400;
        }
      }
    }
  }

  .order-again {
    a {
      display: inline-block;
      text-align: center;
      line-height: 1;
    }
  }

  .woocommerce-customer-details {
    address {
      padding: 12px;
      border-radius: 4px;
      border: 1px solid var(--wp--preset--color--grey-secondary);
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      line-height: 1.3;
      width: 100%;

      p {
        padding-bottom: 0;

        &:empty {
          display: none;
        }
      }
    }
  }

  @include breakpoint(md) {
    .woocommerce-order-overview {

      li {
        padding-right: 24px;
        margin-right: 24px;
        border-right: 1px dashed var(--wp--preset--color--grey-secondary) !important;

        &:last-of-type {
          padding-right: 0;
          margin-right: 0;
          border-right: none !important;
          margin-bottom: 0 !important;
        }
      }
    }

    .cross-sells {
      .products {
        gap: 20px;
        column-gap: 20px;
        margin-bottom: 0;
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
}