@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.page-template-contact-us {
  // For all pages
  &__title {

  }

  &__text {

  }

  .sidebar {

  }

  // For Contact Us Pattern
  .contact-us {
    max-width: 100%;

  // Title and Description
    .hero {
      &__title {

      }

      &__text {

      }
    }

    // Contact Info
    .contact-info {
      &__title {

      }

      &__address {

      }

      &__hours {

      }

      .contact-link-with-icon {
        * {
          word-break: break-all;
        }
        
        @include breakpointMax(lg) {
          .icon-container {
            width: 24px !important;
          }
        }
      }
    }

    // Form
    .sidebar {

      .gform_confirmation_wrapper{
        padding-top: 14px!important;
      }

    }
  }

  // For Group Certifications Pattern
  .group-certifications {
    max-width: 100%;

    // Left Column
    .hero {
      &__title {

      }

      &__text {

      }

      &__faq-title {

      }
    }

    // Right Column
    .sidebar {
      &__footer {

      }

      &__footer-text {

      }

      &__footer-link {

      }
    }
  }
}