@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;


.my-account {
  .woocommerce:has(.login-screen) {
    max-width: 100vw !important;
  }

  .login-screen {

    h2 {
      color: var(--wp--preset--color--primary-dark);
      margin-top: 0;
      font-size: var(--font-2);
      margin-bottom: 24px;
    }

    > .row {
      display: flex;
      flex-wrap: wrap;

      > div {
        flex-basis: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        &:nth-of-type(2) {
          padding: 36px 20px;
          max-width: 606px;
          margin: 0 auto;

          p {
            max-width: 566px;
            padding-bottom: 14px;
            margin-bottom: 0;
            padding-top: 1px;
            line-height: 1;

            &:has(.woocommerce-form-login__rememberme) {
              display: flex;
              align-items: center;
              flex-direction: row-reverse;
            }
          }
        }
      }

      > .image {
        flex-basis: 48%;
        display: none;

        img {
          height: 100%;
          object-fit: cover;
          min-height: 43.75rem;
        }
      }
    }

    .woocommerce-notices-wrapper {
      width: 100%;
      max-width: 566px;
      margin: 0 auto;

      li {
        display: inline-block;
      }

      .woocommerce-error {
        border: none;
        background-color: var(--wp--preset--color--primary);
        border-radius: 5px;
        padding: 26px 32px;
        color: var(--wp--preset--color--white);
        text-align: center;
      }

      .wc-block-components-notice-banner__content{
        flex-wrap: wrap;
        column-gap: 5px;
      }
    }

    .woocommerce-message{
      width: 100%;
      max-width: 566px;
    }

    #customer_login {

      .u-column2 {
        display: none;
      }
    }

    .woocommerce-form {
      margin: 0;
      padding: 0;
      border: none;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;


      h4 {
        color: var(--wp--preset--color--primary);
        margin-top: 0;
        font-size: var(--font-2);
        margin-bottom: 24px;
        flex-basis: 100%;
      }

      .lost_password {
        padding-bottom: 0;
        margin-bottom: 14px;
      }

      .woocommerce-form__label-for-checkbox {
        position: relative;
        line-height: 1;
        display: inline-flex;
        align-items: center;
        margin-left: 14px !important;
        margin-bottom: 0 !important;
        margin-top: 2px!important;

        span {
          font-size: 14px !important;
          font-weight: 400 !important;
          color: var(--wp--preset--color--grey-light-primary);
        }
      }
    }

    .woocommerce-form-row {
      padding: 0;
      flex-basis: 100%;
      margin-bottom: 14px;
      width: 100%;

      &:last-of-type {
        margin-bottom: 0;
      }

      label {
        line-height: 1.4;
      }

      input {
        font-size: var(--font-body);
        color: var(--wp--preset--color--grey-light-primary);
      }
    }

    .woocommerce-ResetPassword {
      max-width: 566px;
      width: 100%;
      margin: 0 auto;
    }

    .col-1 {
      float: none !important;
      width: 100% !important;
    }

    .wc-social-login {
      width: 100%;
      margin-top: 16px;
      a {
        width: 100%;
        max-width: 210px;
        @include social-login;
        border-radius: 30px;
      }
    }

    @include breakpoint(md) {
      > .row {
        > .image {
          display: block;
        }

        > div:nth-of-type(2) {
          flex-basis: 52%;
          padding: 70px 20px 0 40px;
          max-width: 616px;
        }
      }
    }
  }

  .lost_reset_password {
    padding-top: $primary-top-bottom-spacing;
    padding-bottom: $primary-top-bottom-spacing;
  }

  .woocommerce-error li {
    display: block;
  }
}
