@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;

.sidebar {
  @media (min-width: 782px) and (max-width: 1200px) {

  }
}

.single-lesson__sidebar{
  background-color: var(--wp--preset--color--grey-light-secondary);
  position: sticky;
  top: 100px;
  padding-top: 14px;

  &-title {
    padding: 0 20px 20px;
    a{
      color: var(--wp--preset--color--primary-dark);
    }
  }

  &-progress-navigation{
    column-gap: 4px;
    row-gap: 16px!important;
    padding: 0 10px;

    .wp-block-button{
      flex: 1 0 50%;

      &:nth-child(2){
        > a {
          margin-left: auto;
        }
      }

      &__link{
        padding: 12px;
        min-width: auto;
        max-width: 200px;
      }
    }
  }

  @media (max-width: 430px) {
    .single-lesson__sidebar{
      &-progress-navigation{
        justify-content: center !important;
        flex-wrap: wrap;
        .wp-block-button__link{
          margin: auto;
        }
      }
    }
  }

  @include breakpoint(md){
    &-progress-navigation{
      flex-wrap: wrap!important;
      justify-content: center;
      .button-with-arrow-left:has(~.wp-block-button), .button-with-arrow{
        a{
          margin: auto;
        }
      }
    }
  }

  @include breakpoint(xl){
    &-progress-navigation{
      flex-wrap: nowrap!important;
      justify-content: center;
      .wp-block-button{
        &:nth-child(2){
          > a {
            margin-left: auto;
          }
        }
      }
    }
  }
}