@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.home, .page-id-11849115 {
  .hero {
    @include breakpointMax(md) {
      h1{
        font-size: 40px!important;
      }
      &__bottom-bar {
        gap: 18px;

        * {
          text-align: center;
        }
      }
    }
  }

  .featured-products {
    &__item {
      position: relative;
    }

    &__item-container {
      position: relative;
    }

    &__category {
      margin-top: auto !important;
    }

    &__discount-badge {
      position: absolute;
      display: flex;
      align-items: center;
      top:-2px;
      right: -15px;
      width: 100%;
      max-width: 192px;
      height: 40px;
      padding: 0 20px;
      transform: translateY(-50%);

      &::before {
        content: "";
        height: 0;
        width: 0;
        display: block;
        position: absolute;
        top: 0;
        left: -29px;
        border-top: 17px solid var(--wp--preset--color--accent);
        border-bottom: 23px solid var(--wp--preset--color--accent);
        border-right: 29px solid transparent;
        border-left: 29px solid transparent;
      }

      &::after {
        content: "";
        height: 0;
        width: 0;
        display: block;
        position: absolute;
        bottom: -15px;
        right: 0;
        border-top: 15px solid var(--wp--preset--color--accent-dark);
        border-right: 15px solid transparent;
      }
    }

    @include breakpointMax(md) {
      &__item-width-discount &__price {
        font-size: 20px !important;
      }
    }
  }

  .trustpilot {

  }
  
  .money-back {
    &__bottom-bar {
      display: inline-flex !important;
      align-items: center !important;
    }

    @include breakpointMax(md) {
      text-align: center;

      &__badge img{
        width: 162px!important;
      }
    }
  }

  .get-certified-today {
    &__title {

    }

    &_list-item {

    }

    @include breakpointMax(md) {
      &__title {
        text-align: center;
      }
    }
  }

  .testimonials {
    &__title {

    }

    &__text {

    }

    &__items {

    }

    &__item-container {

    }

    @include breakpointMax(lg) {
      &__item-container {
        width: 100%;
        max-width: 350px;
        margin: 0 auto;
      }
    }
  }

  .certifications {
    &__container {

    }

    @include breakpointMax(lg) {
      &__container {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        flex-wrap: wrap;
      }

      &__item {
        flex-basis: 25%;
      }
    }

    @include breakpointMax(md) {
      &__container {
        display: flex !important;
      }
    }
  }
}