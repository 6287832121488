@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.button-full-width {
  width: 100%;

  a {
    width: 100%;
  }
}

.button-with-arrow {
  a {
    @include button-with-arrow;
  }
}

.button-with-arrow-left{
  a {
    @include button-with-arrow-left;
  }
}

.button-with-card-icon {
  a {
    display: flex;
    align-items: center;

    &:after {
      content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="23" height="21" viewBox="0 0 23 21" fill="none"><g clip-path="url(%23clip0_128_5745)"><path d="M0.25 1.75V4.25H22.75V1.75H0.25ZM0.25 5.5V19.25H22.75V5.5H0.25ZM2.75 16.75L4 14.25H10.25L11.5 16.75H2.75ZM7.125 8C7.78804 8 8.42393 8.26339 8.89277 8.73223C9.36161 9.20107 9.625 9.83696 9.625 10.5C9.625 11.163 9.36161 11.7989 8.89277 12.2678C8.42393 12.7366 7.78804 13 7.125 13C6.46196 13 5.82607 12.7366 5.35723 12.2678C4.88839 11.7989 4.625 11.163 4.625 10.5C4.625 9.83696 4.88839 9.20107 5.35723 8.73223C5.82607 8.26339 6.46196 8 7.125 8ZM14 8H14.625H19.625H20.25V9.25H19.625H14.625H14V8ZM14 10.5H14.625H19.625H20.25V11.75H19.625H14.625H14V10.5ZM14 13H14.625H19.625H20.25V14.25H19.625H14.625H14V13Z" fill="white"/></g><defs><clipPath id="clip0_128_5745"><rect width="22.5" height="20" fill="white" transform="translate(0.25 0.5)"/></clipPath></defs></svg>');
      width: 22px;
      height: 25px;
      margin-left: 10px;
    }
  }
}

.button-with-video-icon {
  a {
    display: flex;
    align-items: center;

    &:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='14' viewBox='0 0 16 14' fill='none'%3E%3Cg clip-path='url(%23clip0_143_804)'%3E%3Cpath d='M0 3.5C0 2.53477 0.784766 1.75 1.75 1.75H8.75C9.71523 1.75 10.5 2.53477 10.5 3.5V10.5C10.5 11.4652 9.71523 12.25 8.75 12.25H1.75C0.784766 12.25 0 11.4652 0 10.5V3.5ZM15.2879 2.72891C15.5723 2.88203 15.75 3.17734 15.75 3.5V10.5C15.75 10.8227 15.5723 11.118 15.2879 11.2711C15.0035 11.4242 14.659 11.4078 14.3883 11.2273L11.7633 9.47734L11.375 9.21758V8.75V5.25V4.78242L11.7633 4.52266L14.3883 2.77266C14.6562 2.59492 15.0008 2.57578 15.2879 2.72891Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_143_804'%3E%3Crect width='15.75' height='14' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");;
      height: 14px;
      margin-right: 10px;
      display: block;
    }
  }
}

.button-with-question-circle-icon {
  a {
    display: flex;
    align-items: center;

    &:before {
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='1em' viewBox='0 0 512 512'%3E%3C!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --%3E%3Cstyle%3Esvg%7Bfill:%23ffffff%7D%3C/style%3E%3Cpath d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM169.8 165.3c7.9-22.3 29.1-37.3 52.8-37.3h58.3c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24V250.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1H222.6c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z'/%3E%3C/svg%3E");;
      height: 14px;
      margin-right: 10px;
      display: block;
    }
  }
}

.button-secondary {
  .wp-block-button__link {
    @include button-secondary;
  }
}

.button-accent {
  .wp-block-button__link {
    color: var(--wp--preset--color--white);
    background-color: var(--wp--preset--color--primary-light);
    border-color: var(--wp--preset--color--primary-light);

    &:hover {
      color: var(--wp--preset--color--white);
      background-color: var(--wp--preset--color--primary-dark);
      border-color: var(--wp--preset--color--primary-dark);
    }
  }
}

.button-transparent {
  .wp-block-button__link {
    color: var(--wp--preset--color--grey-light-primary);
    background-color: transparent;
    border-color: transparent;

    &:hover {
      background-color: var(--wp--preset--color--grey-light-secondary);
      border-color: transparent;
    }
  }
}


/* BAck to top button */
.toptobutton {
  height: 50px;
  width: 50px;
  position: fixed;
  right: 20px;
  bottom: 74px;
  z-index: 1;
  background-repeat: no-repeat;
  display: none;
  border-radius: 50px;
  text-align: center;

  background-color: #04a6d4;
}

@media (max-width: 790px) {
  .toptobutton {
    height: 32px;
    width: 32px;
    opacity: 0.65;
  }

  .toptobutton i {
    top: 20% !important;
  }
}

.toptobutton i {
  color: #0b333e !important;
  top: 28%;
  position: relative;
}
