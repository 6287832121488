@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

// All Products Page
.page-id-10652353,
.page-id-11349020,
.page-id-11349735{
  .hero {
    padding-top: $primary-top-bottom-spacing !important;
    padding-bottom: $primary-top-bottom-spacing !important;

    &__title {

    }
  }

  // Anchors
  .navigation {
    @include breakpointMax(md) {
      padding-bottom: 0 !important;
    }
  }
}