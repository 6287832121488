@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;
@use "../../abstracts/svg" as *;

.additional-questions-form {
  h4{
    margin-top: 0;
    margin-bottom: 20px;
    color: var(--wp--preset--color--primary);
    text-align: center;
  }

  input[type="submit"] {
    width: 100%!important;
    max-width: 220px;
    margin: 0 auto!important;
    display: block!important;
    background-color: var(--wp--preset--color--primary-dark) !important;
    border-color: var(--wp--preset--color--primary-dark) !important;

    &:hover {
      background-color: var(--wp--preset--color--primary-light) !important;
      border-color: var(--wp--preset--color--primary-light) !important;
    }
  }

  .gform_confirmation_message{
    font-size: var(--font-body);
    color: var(--wp--preset--color--grey-light-primary);
    text-align: center;
    margin-bottom: 20px;
    font-weight: 400;
  }
}