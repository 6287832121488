@use "../abstracts/mixins" as *;
@use "../abstracts/variables" as *;

.tabs-primary {
  .tabs_list_container {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    gap: 2px;
    border: none;
  }

  .tabs_list_item {
    padding: 12px 20px;
    color: var(--wp--preset--color--grey-light-primary);
    background: var(--wp--preset--color--grey-medium);
    border: 1px solid var(--wp--preset--color--grey-medium);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    cursor: pointer;
    transition: $primary-animation;

    &:hover,
    &.active {
      color: var(--wp--preset--color--dark-primary);
      background: var(--wp--preset--color--grey-light-secondary);
      border-color: var(--wp--preset--color--grey-medium);
    }
    p{
      font-weight: 400!important;
    }
  }

  .tabs_content_container {
    padding: 20px 24px;
    background: #F8F8F8;

    > div {
      display: none;

      &.active {
        display: block;
      }
    }

    p:last-child {
      padding-bottom: 0;
    }
  }

  @include breakpoint(lg) {
    .tabs_list_container {
      flex-direction: row !important;
      justify-content: center;
      gap: 2px;
    }

    .tabs_content_container {
      padding: 32px;
    }
  }
}

.practice-quiz-tabs {
  .tabs_list_container {
    justify-content: flex-start;

    @include breakpoint(lg) {
      padding-left: 40px;
    }
  }
}

.tabs-faq {
  .tabs_content_all {
    .ac {
      border: none;
    }
  }

  .tabs_list_item {
    margin: 0;
    padding: 10px 0;
    border-bottom: 1px solid #C8CCD9!important;
    color: var(--wp--preset--color--grey-light-primary)!important;
    cursor: pointer;
    position: relative;
    transition: $primary-animation;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 12px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='13' viewBox='0 0 14 13' fill='none'%3E%3Cpath d='M10.4931 7.10539C10.8279 6.77057 10.8279 6.22682 10.4931 5.892L6.20735 1.60628C5.87253 1.27146 5.32878 1.27146 4.99396 1.60628C4.65914 1.9411 4.65914 2.48485 4.99396 2.81968L8.67432 6.50003L4.99664 10.1804C4.66182 10.5152 4.66182 11.059 4.99664 11.3938C5.33146 11.7286 5.87521 11.7286 6.21003 11.3938L10.4957 7.10807L10.4931 7.10539Z' fill='black'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
      height: 14px;
      transform: translateY(-50%);
    }

    &.active {
      color: var(--wp--preset--color--secondary)!important;

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='11' viewBox='0 0 7 11' fill='none'%3E%3Cpath d='M6.49307 6.10539C6.82789 5.77057 6.82789 5.22682 6.49307 4.892L2.20735 0.606283C1.87253 0.271461 1.32878 0.271461 0.993959 0.606283C0.659138 0.941104 0.659138 1.48485 0.993959 1.81968L4.67432 5.50003L0.996638 9.18039C0.661817 9.51521 0.661817 10.059 0.996638 10.3938C1.33146 10.7286 1.87521 10.7286 2.21003 10.3938L6.49575 6.10807L6.49307 6.10539Z' fill='%23D9272E'/%3E%3C/svg%3E");
      }
    }

    p {
      color: inherit;
      padding-bottom: 0 !important;
    }
  }

  .accordion-container {
    .ac {
      border: none !important;

      .wp-block-heading {
        padding: 8px 35px 8px 0;
        color: var(--wp--preset--color--primary);
        line-height: 1.14;
        border-bottom: 1px solid #C8CCD9;
        position: relative;
        display: flex;
        gap: 20px;
        justify-content: space-between;
        font: 400 var(--font-4) var(--wp--preset--font-family--font-body) !important;

        &:after {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='17' viewBox='0 0 14 17' fill='none'%3E%3Cpath d='M6.29371 12.4086C6.68434 12.7992 7.31871 12.7992 7.70934 12.4086L12.7093 7.40861C13.1 7.01798 13.1 6.38361 12.7093 5.99298C12.3187 5.60236 11.6843 5.60236 11.2937 5.99298L6.99996 10.2867L2.70621 5.99611C2.31559 5.60548 1.68121 5.60548 1.29059 5.99611C0.899963 6.38673 0.899963 7.02111 1.29059 7.41173L6.29059 12.4117L6.29371 12.4086Z' fill='%23333333'/%3E%3C/svg%3E");
          display: block;
          align-self: flex-start;
        }
      }

      &.is-active .wp-block-heading {
        color: var(--wp--preset--color--primary-dark);

        &:after {
          transform: translateY(-50%) rotate(180deg);
        }
      }

      .ac-panel {
        padding: 0 !important;

        & > *:first-child {
          padding-top: 8px;
        }

        & > *:last-child {
          padding-bottom: 24px !important;
        }
      }
    }
  }

  .tabs_main_wrapper {
    display: grid;
  }

  @include breakpoint(md) {
    .tabs_main_wrapper {
      grid-template-columns: 35% auto !important;
      column-gap: 60px;
    }
  }
}
