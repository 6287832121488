@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;

.product-card {
  position: relative;
  scroll-margin-top: $scroll-top-offset;

  .wp-block-columns {
    height: 100%;
  }

  h3 {
    color: var(--wp--preset--color--primary) !important;
  }

  &__description {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  &__price {
    margin-top: auto;
  }

  @include breakpointMax(md) {
    padding-top: 20px !important;

    &:nth-child(even) {
      order: 1;
    }

    &__description {
      text-align: center;
    }
    
    &__button {
      justify-content: center;
    }
  }
}