@use "../../abstracts/mixins" as *;
@use "../../abstracts/variables" as *;


//Modules Item
$modulesItemTitleWrapperBackgroundColor: var(--wp--preset--color--grey-light-secondary);

.modules{

  &__heading{

  }

  &-item {
    &__title--wrapper{
      padding: 16px 20px!important;
      background-color: $modulesItemTitleWrapperBackgroundColor!important;
    }

    &__lessons {
      @include lessons-list-item;
    }
  }
}